////to get verify product model

export default class VerifyProduct {
    VerificationMode = "Web";
    QrData = "";
    VerificationType = 0;
    CompanyPrefix="";
    ProductId=0;
    Userlatitude = 0;
    Userlongitude = 0;
    UserCity = "";
    UserState = "";
    UserCountry = "";
    UserZip = "";
    UserAddress = "";
    ScratchCode = "";
    VerifiedBy = "";
}