import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            
            <div className="row footer">
                <div className="col-md-6 ">Copyright 2021 | Shiva Engineering Co Pvt. Ltd.  | All Rights Reserved.</div>
                <div className="col-md-6 text-right designedby">Design & Developed by <a href="https://www.holostik.com" target="_blank">  Holostik India Limited</a></div>
            </div>
        )
    }
}
