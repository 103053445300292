import { authHeader, handleResponse } from '../helpers';
export const productMasterService = {
    createProduct,
    updateProduct,
    fetchProductData,
    inActiveRow,
    fetchProductDataID,
    fetchProductName,
    fetchProductDLL
};

function createProduct(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/CreateProduct`, requestOptions)
        .then(handleResponse);
}

function updateProduct(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/UpdateProduct`, requestOptions)
        .then(handleResponse);
}

function fetchProductData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/GetProducts`, requestOptions)
        .then(handleResponse);
}

function fetchProductDataID(productId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/GetProductID/${productId}`, requestOptions)
        .then(handleResponse);
}

function fetchProductName(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Mapping/GetProductName`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(productId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/InActiveRow/${productId}/${isActive}`, requestOptions)
        .then(handleResponse);
}

function fetchProductDLL() {
    ;
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}ProductMaster/GetProductDLL`, requestOptions)
        .then(handleResponse);
}


