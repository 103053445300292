import { FeedbackRptConstants } from '../constants';

export function FeedbackRpt(state = {}, action) {
    switch (action.type) {
        case FeedbackRptConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case FeedbackRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FeedbackRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case FeedbackRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
            case FeedbackRptConstants.ServiceReq_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FeedbackRptConstants.ServiceReq_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case FeedbackRptConstants.ServiceReq_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case FeedbackRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true
            };
        case FeedbackRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas
            };
        case FeedbackRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}