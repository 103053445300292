import { combineReducers } from 'redux';
import { product } from './productReducer';
import { user } from './userReducer';
import { alert } from './alertReducer';
import { role } from "./roleMasterReducer";
import { dashboard } from "./dashboardReducer";
import { login } from "./loginReducer";
import { menu } from "./menuReducer";
import { changePassword } from "./changePasswordReducer";
import {mapping} from "./mappingReducer";
import {issueNo} from './issueNoReducer';
import {damage} from './damageReducer';
import { verification } from "./verificationLogReducer";
import { mappingRpt } from "./mappingRptReducer";
import {FeedbackRpt} from "./FeedbackRptReducer";
import {WarrantyRpt} from "./warrantyRptReducer";
import {issueRpt} from "./issueRptReducer"
import {SqlEditor} from "./SqlEditorReducer";
import {demapping} from './demappingReducer'; 
import { productAuthentication } from './productAuthenticationReducer';
import { productConfiguration } from './productConfigurationReducer';
import { company } from "./companyReducer";
import {DemappingRpt} from "./DemappingRptReducer";
import {DamageRpt} from "./DamageRptReducer";

const rootReducer = combineReducers({
    user,
    alert,
    product,
    role,
    dashboard,
    login,
    menu,
    changePassword,
    mappingRpt,
    FeedbackRpt,
    SqlEditor,
    issueNo,
    damage,
    verification,
    mapping,
    verification,
    demapping,
    productAuthentication,
    productConfiguration,
    company,
    WarrantyRpt,
    issueRpt,
    DamageRpt,
    DemappingRpt
});
 
export default rootReducer;