import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TextField from '@material-ui/core/TextField';
import { Calendar } from 'primereact/calendar';
import { alertActions, FeedbackRptActions } from "../../actions";
import { connect } from 'react-redux';
import tableRequest from '../../models/tableRequest.js';
import { Toast } from 'primereact/toast';
import { CSVLink } from "react-csv";

class ServiceReq extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
        this.FilterData = this.FilterData.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.state = {
            loading: false,
            FromDate: new Date(),
            ToDate: new Date(),
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            visible: false,
            sortOrder: '',
            datalist: [],
        }
    }

    componentDidMount() {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 7);
        this.tblResponse.FromDate = date.toLocaleDateString();
        this.tblResponse.ToDate = new Date().toLocaleDateString();
        this.setState({
            FromDate: date,
            ToDate: new Date()
        });
        this.props.fetchApi(this.tblResponse);
        this.props.exportingData(this.tblResponse);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.FeedbackList !== this.props.FeedbackList) {
            if (this.props.FeedbackList) {
                var e = this.props.FeedbackList;
                this.setState({
                    totalRecords: e[0].TotalRows
                });
            }
        }
    }

    handleImageClick(imageUrl) {
        window.open(imageUrl, '_blank');
    }

    photoBodyTemplate = (rowData) => {
        if (rowData.image) {
            return (
                <img
                    src={rowData.image}
                    alt="Image"
                    style={{ cursor: 'pointer', width: '50px' }}
                    onClick={() => this.handleImageClick(rowData.image)}
                />
            );
        }
        return null;
    }
    photoBodyTemplate1 = (rowData) => {
        if (rowData.warrantyimage) {
            return (
                <img
                    src={rowData.warrantyimage}
                    alt="Image"
                    style={{ cursor: 'pointer', width: '50px' }}
                    onClick={() => this.handleImageClick(rowData.warrantyimage)}
                />
            );
        }
        return null;
    }
    photoBodyTemplate2 = (rowData) => {
        if (rowData.invoice) {
            return (
                <img
                    src={rowData.invoice}
                    alt="Image"
                    style={{ cursor: 'pointer', width: '50px' }}
                    onClick={() => this.handleImageClick(rowData.invoice)}
                />
            );
        }
        return null;
    }

    GetFeedback() {
        let ToDt = new Date();
        let FrmDt = new Date();
        FrmDt.setDate(ToDt.getDate() - 7);

        const myFmDt = this.getCurrentDateYYYYMMDD(FrmDt);
        const myToDt = this.getCurrentDateYYYYMMDD(ToDt);

        this.tblResponse.FromDate = myFmDt;
        this.tblResponse.ToDate = myToDt;
        this.props.fetchApi(this.tblResponse);
    }

    FilterData() {
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;

        this.props.fetchApi(this.tblResponse);
    }

    getCurrentDateYYYYMMDD(dt) {
        var dd = String(dt.getDate()).padStart(2, '0');
        var mm = String(dt.getMonth() + 1).padStart(2, '0');
        var yyyy = dt.getFullYear();

        dt = yyyy + '/' + mm + '/' + dd;
        return dt;
    }

    handleChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case 'FromDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select from date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select from date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.ToDate instanceof Date)) {
                    const startDate = value;
                    const endDate = this.state.ToDate;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        // this.refs.Messchild.showError("From date must be less than to date.");
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'From date must be less than to date.', life: 3000 });
                        return;
                    }
                }

                break;
            case 'ToDate':
                if (value === null || !(value instanceof Date)) {
                    this.formIsValid = false;
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please select to date.', life: 3000 });
                    // this.refs.Messchild.showError("Please select to date.");
                    return;
                }
                else if (!(value.toLocaleDateString().match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/))) {
                    this.formIsValid = false;
                    // this.refs.Messchild.showError("Please enter only date.");
                    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please enter only date.', life: 3000 });
                    return;
                }
                else if ((value instanceof Date) && (this.state.FromDate instanceof Date)) {
                    const startDate = this.state.FromDate;
                    const endDate = value;
                    if (!(Date.parse(startDate) <= Date.parse(endDate))) {
                        this.formIsValid = false;
                        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'To date must be greater than from date.', life: 3000 });
                        // this.refs.Messchild.showError("To date must be greater than from date.");
                        return;
                    }
                }

                break;
            default:
                break;
        }

        this.setState({ [name]: value });
    }
    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        this.tblResponse.FromDate = this.state.FromDate;
        this.tblResponse.ToDate = this.state.ToDate;
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        // this.setState({
        //     loading: true
        // });
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    removeImageColumn = (data) => {
        return data.map(item => {
            const { image, ...rest } = item; // Destructure to remove the "image" property
            return rest;
        });
    };

    //to sort
    onSort(event) {
        this.props.loadingData(true);;
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDt" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    render() {
        const filteredData = this.removeImageColumn(this.props.List);
        return (
            <div className="datatable-filter-demo">
                <Toast ref={(el) => this.toast = el} />
                <div className="row mb-3">
                    <div className="col-md-3">
                        <h4>Service Request</h4>
                    </div>
                    <div className="col-md-9">
                        <div className="row text-right">
                            <div className="col-md-3 col-sm-12">
                                <Calendar placeholder="From Date" showIcon="true" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} appendTo={document.getElementById('root')}></Calendar></div>
                            <div className="col-md-3 col-sm-12">
                                <Calendar placeholder="To Date" showIcon="true" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} appendTo={document.getElementById('root')}></Calendar>
                            </div>
                            <div className="col-md-3 col-sm-12 text-center buttone">
                                <button type="button" onClick={this.FilterData} className="btn btn-primary p-2 pl-3 pr-3">Submit</button>
                                &nbsp;&nbsp;&nbsp;
                                <CSVLink data={filteredData} headers={this.state.headers} filename={"Service request.csv"}
                                    // className="btn btn-primary ml-2"
                                    target="_blank"><button className="btn btn-success p-2 pl-3 buttone1">Export</button></CSVLink>

                            </div>
                            <div className="col-md-3 col-sm-12">
                                <TextField id="standard-basic" size="small" variant="outlined" className="fullwidth" label="Search" onInput={this.searchFilter} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <DataTable value={this.props.FeedbackList}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.totalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>
                        <Column field="ProductName" disabled header="Product Name " sortable></Column>
                        <Column field="UserName" disabled header="Name " sortable></Column>
                        <Column field="MobileNo" disabled header="Mobile No." sortable></Column>
                        <Column field="Address" disabled header="Address" sortable></Column>
                        <Column field="state" disabled header="State" sortable></Column>
                        <Column field="Remark" disabled header="Remark" sortable></Column>
                        <Column field="image" header="image" body={this.photoBodyTemplate}></Column>
                        <Column field="warrantyimage" header="Warranty image" body={this.photoBodyTemplate1}></Column>
                        <Column field="invoice" header="Invoice image" body={this.photoBodyTemplate2}></Column>
                        <Column field="RemarkUpdatedOn" disabled header="Service Request Date" sortable></Column>

                    </DataTable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        FeedbackList: state.FeedbackRpt.items,
        List: state.FeedbackRpt.exportitems == undefined ? [] : state.FeedbackRpt.exportitems,
        loading: state.verification.loding
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(FeedbackRptActions.ServiceReq(obj))
        },
        loadingData: (isLoding) => dispatch(FeedbackRptActions.loadingData(isLoding)),
        exportingData: (obj) => dispatch(FeedbackRptActions.exportData(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceReq)
