import { dashboardConstants } from '../constants';

export function dashboard(state = {}, action) {   
    switch (action.type) {
        case dashboardConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case dashboardConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case dashboardConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
                
        default:
            return state
    }
}
