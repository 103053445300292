import { SqlEditorServices } from '../services/SqlEditorServices';
import { SqlEditorConstants } from '../constants/SqlEditorConstants';
import { alertActions } from '.';
import { history } from '../helpers';

export const SqlEditorActions = {
    executeScript
};

function executeScript(script) {
    return dispatch => {
        dispatch(request());

        SqlEditorServices.executeScript(script).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: SqlEditorConstants.RUN_SCRIPT_REQUEST } }
    function success(data) { return { type: SqlEditorConstants.RUN_SCRIPT_SUCCESS, data } }
    function failure(error) { return { type: SqlEditorConstants.RUN_SCRIPT_FAILURE, error } }
};


