import { authHeader, handleResponse } from '../helpers';
export const issueNoService = {
    createIssueNo,
    fetchIssueNoData,
    fetchFromNumbersData,
    fetchGenericProducts
};

function createIssueNo(data) {  
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/CreateIssueNumber`, requestOptions)
    .then(handleResponse);
}

function fetchIssueNoData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetIssueNumbers`, requestOptions)
    .then(handleResponse);
}

function fetchFromNumbersData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetFromNumbers`, requestOptions)
    .then(handleResponse);
}

function fetchGenericProducts() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}IssueNo/GetProductsGeneric`, requestOptions)
    .then(handleResponse);
}





