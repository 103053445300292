import { demappingConstants } from '../constants';

export function demapping(state = {}, action) {
    switch (action.type) {
        case demappingConstants.DIALOG_REQUEST:
            return {
                ...state,
                showDialog: action.isbool
            };
        case demappingConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case demappingConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.datas
            };
        case demappingConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case demappingConstants.GETALLDT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case demappingConstants.GETALLDT_SUCCESS:
            return {
                ...state,
                demapDt: action.datas
            };
        case demappingConstants.GETALLDT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case demappingConstants.CREATE_REQUEST:
            return {
                ...state,
                creating: true
            };
        case demappingConstants.CREATE_SUCCESS:
            return {
                ...state,
                items: action.data
            };
        case demappingConstants.CREATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case demappingConstants.UPDATE_REQUEST:
            return {
                ...state,
                items: state.items.map(item =>
                    item.demappingId == action.data.demappingId ? { ...item, updating: true } : item
                )
            };
        case demappingConstants.UPDATE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.demappingId === action.data.demappingId ? action.data : item
                )
            };
        case demappingConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case demappingConstants.DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            };
        case demappingConstants.DELETE_SUCCESS:
            return {
                ...state,
                items: state.items.map(item =>
                    item.demappingId === action.data ? {
                        CreatedBy: item.CreatedBy,
                        CreatedDt: item.CreatedDt,
                        demappingId: item.demappingId,
                        productName: item.productName,
                        UpdatedBy: item.UpdatedBy,
                        UpdatedDt: item.UpdatedDt,
                        FromNo:item.FromNo,
                        ToNo:item.ToNo,
                        Qty:item.Qty
                    } : item
                )
            };
        case demappingConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case demappingConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        default:
            return state
    }
}
