import { authHeader, handleResponse } from '../helpers';
export const mappingRptService = {
    fetchMappingData,
    fetchExcelData
};

function fetchMappingData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}MappingRpt/GetMappingReport`, requestOptions)
        .then(handleResponse);
}

function fetchExcelData(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
   };

    return fetch(`${process.env.REACT_APP_API_URL}MappingRpt/GetMappingReport`, requestOptions)
        .then(handleResponse);
}