import { productAuthenticationConstants } from "../constants";

export function productAuthentication(state = {}, action) {
  switch (action.type) {
    case productAuthenticationConstants.FEEDBACK_DIALOG_REQUEST:
      return {
        ...state,
        showDialogFeedback: action.isbool,
      };
    case productAuthenticationConstants.SERVICE_DIALOG_REQUEST:
      return {
        ...state,
        ShowDialogService: action.isbool,
      };
    case productAuthenticationConstants.PRODUCT_DIALOG_REQUEST:
      return {
        ...state,
        ShowDialogProduct: action.isbool,
      };
    case productAuthenticationConstants.WARRANTY_DIALOG_REQUEST:
      return {
        ...state,
        WarrantyForm: action.isbool,
      };
    case productAuthenticationConstants.UPDATE_FEEDBACK_REQUEST:
      return {
        ...state,
        isfeedback: true,
      };
    case productAuthenticationConstants.UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.datas,
      };
    case productAuthenticationConstants.UPDATE_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.UPDATE_RATING_REQUEST:
      return {
        ...state,
        israting: true,
      };
    case productAuthenticationConstants.UPDATE_RATING_SUCCESS:
      return {
        ...state,
        rating: action.data,
      };
    case productAuthenticationConstants.UPDATE_RATING_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productAuthenticationConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productAuthenticationConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case productAuthenticationConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case productAuthenticationConstants.CREATE_SUCCESS:
      return {
        ...state,
        // items: [action.data, ...state.items]
        items: [action.data],
      };
    case productAuthenticationConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.productAuthenticationId == action.data.productAuthenticationId
            ? { ...item, updating: true }
            : item
        ),
      };
    case productAuthenticationConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.productAuthenticationId === action.data.productAuthenticationId
            ? action.data
            : item
        ),
      };
    case productAuthenticationConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case productAuthenticationConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.productAuthenticationId === action.data
            ? {
              CreatedBy: item.CreatedBy,
              CreatedDt: item.CreatedDt,
              productAuthenticationId: item.productAuthenticationId,
              productName: item.productName,
              UpdatedBy: item.UpdatedBy,
              UpdatedDt: item.UpdatedDt,
              FromNo: item.FromNo,
              ToNo: item.ToNo,
              Qty: item.Qty,
            }
            : item
        ),
      };
    case productAuthenticationConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.GETPRDCTDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productAuthenticationConstants.GETPRDCTDDL_SUCCESS:
      return {
        ...state,
        prdctddl: action.datas,
      };
    case productAuthenticationConstants.GETPRDCTDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productAuthenticationConstants.GETPRDCTID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productAuthenticationConstants.GETPRDCTID_SUCCESS:
      return {
        ...state,
        productID: action.datas,
      };
    case productAuthenticationConstants.GETPRDCTID_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productAuthenticationConstants.GETWARRANTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productAuthenticationConstants.GETWARRANTY_SUCCESS:
      return {
        ...state,
        warranty: action.datas,
      };
    case productAuthenticationConstants.GETWARRANTY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productAuthenticationConstants.CREATE_WARRANTY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case productAuthenticationConstants.CREATE_WARRANTY_SUCCESS:
      return {
        ...state,
        // items: [action.data, ...state.items]
        WarrantyForm: action.datas,
      };
    case productAuthenticationConstants.CREATE_WARRANTY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productAuthenticationConstants.CREATE_WARRANTY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case productAuthenticationConstants.CREATE_WARRANTY_SUCCESS:
      return {
        ...state,
        // items: [action.data, ...state.items]
        WarrantyForm: action.datas,
      };
    case productAuthenticationConstants.CREATE_WARRANTY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case productAuthenticationConstants.INSERT_VERIFICATION_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case productAuthenticationConstants.INSERT_VERIFICATION_SUCCESS: 
      return {
        ...state,
        // items: [action.data, ...state.items]
        VerificationId: action.datas,
      };
    case productAuthenticationConstants.INSERT_VERIFICATION_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
