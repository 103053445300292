import React, { Component } from 'react';
import './App.css';
// import './DataTableDemo.css';
import Login from './views/Login';
import ProductAuthentication from './views/Pa';
import Template from './templates/Template';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { alertActions } from '../src/actions';
import Dashboard from './views/Dashboard';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      show: true,
      authPage: ' ',
      alertHeading: '',
      urlParams: '',
      showToaster: false,
    }
  }

  componentDidMount() {
    // disable right click
    // document.addEventListener('contextmenu', event => event.preventDefault());
    // document.onkeydown = function (e) {
    //   // disable F12 key
    //   if (e.keyCode == 123) {
    //     return false;
    //   }
    // }

    this.props.clear();
    this.setState({ urlParams: window.location.pathname });
    // if (urlParams === '/dashboard') {
    //   this.setState({
    //     authPage: 'login'
    //   })
    // }
    // if (this.state.authPage === 'login')
    // {

    let storeCollector = JSON.parse(sessionStorage.getItem("LoggedInUser"));
    if (storeCollector && storeCollector.login) {
      this.setState({
        login: true
      })
    } else {
      this.setState({
        login: false
      })
    }
  }


  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {

    if (Object.keys(this.props.alert).length > 0) {
      setTimeout(() => this.props.clear(), 6000)
    }

    return (
      <>
        {this.props.alert.message &&
          <div style={{ zIndex: 99999 }} className={`row alert ${this.props.alert.type} ${this.state.show ? 'show' : 'hide'}`}>
            <div className="col-md-2">
              {this.props.alert.type == 'alert-warning' ? <i className="pi pi-exclamation-triangle mr-3" style={{ 'fontSize': '2em' }}></i> : this.props.alert.type == 'alert-success' ? <i className="pi pi-check mr-3" style={{ 'fontSize': '2em' }}></i> : this.props.alert.type == 'alert-danger' ? <i className="pi pi-times mr-3" style={{ 'fontSize': '2em' }}></i> : null}
            </div>
            <div className="col-md-10">
              <h5>{this.props.alert.type == 'alert-warning' ? 'Warn Message' : this.props.alert.type == 'alert-success' ? 'Success Message' : this.props.alert.type == 'alert-danger' ? 'Error Message' : null}</h5>
              {this.props.alert.message}
            </div>
            {/* <span style={{cursor:'pointer'}} onClick={()=> this.props.clear()}>X</span> */}
          </div>
        }
        <Router>
          <Switch>
            <Route path="/pa" component={ProductAuthentication} />
            <Route path="/login" Component={Login} />
            <Route path="/dashboard" Component={Template} />
          </Switch>
        </Router>

        {this.state.login ? (this.state.urlParams == '/pa' ? null : <Template />) : (this.state.urlParams == '/pa' ? null : <Login />)}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alert,

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => {
      dispatch(alertActions.clear());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)