import { authHeader, handleResponse } from '../helpers';
export const IssueRptService = {
    fetchIssueRptData   
};

function fetchIssueRptData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}Reports/GetIssueReport`, requestOptions)
        .then(handleResponse);
}