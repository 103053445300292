import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { dashboardActions, issueNoActions, productAuthenticationActions } from "../actions";
import tableRequest from "../models/tableRequest.js";

import poonirice from "./../assets/images/pooni-rice.jpg";
import basmatirice from "./../assets/images/basmati-rice.jpg";
import boiledrice from "./../assets/images/boiled-rice.jpg";
import streemrice from "./../assets/images/steem-rice.jpg";

import "./Sidebar.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.state = {
      globalFilter: null,
      first: 0,
      rows: 5,
      totalRecords: 0,
      sortOrder: '',
      loading: false
    };
    this.basicData = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Pending",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "#42A5F5",
        },
        {
          label: "Active",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: "#FFA726",
        },
      ],
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                }
                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                }
            }
        }
    this.props.fetchApi(this.tblResponse);
    this.props.fetchMappingApi(this.tblResponse);
    this.props.getPrdctDDL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
        if (prevProps.MappingData !== this.props.MappingData) {
            // this.props.myProp has a different value
            if (this.props.MappingData) {
                var e = this.props.MappingData;
                this.setState({
                    totalRecords: e[0].TotalRows
                });
            }
        }
    } else {
        if (this.state.totalRecords !== 0)
            this.setState({
                totalRecords: 0,
                first: 0
            });
    }

    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
        this.setState({
            FromNo: this.props.MaxFromNo
        });
    }
  }

  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchMappingApi(this.tblResponse);
}

//to sort
onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchMappingApi(this.tblResponse);
}


onPage(event) {
    
    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchMappingApi(this.tblResponse);
}

  render() {
    const chartdata = {
      labels: this.props.Months,
      datasets: [
        {
          label: "Issued Numbers",
          data: this.props.MappingChart,
          fill: false,
          borderColor: "#4bc0c0",
        },
        {
          label: "De-Mapped Numbers",
          data: this.props.DeMappingChart,
          fill: false,
          borderColor: "#9bc0c9",
        },
      ],
    };

    return (
      <div className="container-fluid ">
        {/* <div style={{fontSize:'18px', color:"grey"}} className="text-right mr-3">Total Scans: {this.props.TotalScans}</div> */}
        <div className="m-top"> 
          <div className="row state-overview">
            <div className="col-lg-3 col-sm-6 service-card">
              <section className="card1">
                <div className="symbol terques">
                <i className="fa fa-cubes"></i>
                </div>
                <div className="value">
                  <h1 className="count">{this.props.TotalProducts}</h1>
                  <p>Products</p>
                </div>
              </section>
            </div>
            <div className="col-lg-3 col-sm-6 service-card">
              <section className="card1">
                <div className="symbol red">
                <i className="fa fa-list"></i>
                </div>
                <div className="value">
                  <h1 className=" count2">{this.props.TotalIssue}</h1>
                  <p>Issued</p>
                </div>
              </section>
            </div>
            <div className="col-lg-3 col-sm-6 service-card">
              <section className="card1">
                <div className="symbol yellow">
                <i className="fa fa-object-ungroup"></i>

                </div>
                <div className="value">
                  <h1 className=" count3">{this.props.TotalDeMapping}</h1>
                  <p>Demapped</p>
                </div>
              </section>
            </div>

            <div className="col-lg-3 col-sm-6 service-card">
              <section className="card1">
                <div className="symbol blue">
                <i className="fa fa-trash"></i>
                </div>
                <div className="value">
                  <h1 className=" count4">{this.props.TotalDamage}</h1>
                  <p>Damaged</p>
                </div>
              </section>
            </div>
          </div>
        </div>

      

        <div className="row m-top">
          <div className="col-md-6 boxx mt-3">
            <div className="cards">
              <Chart type="line" data={chartdata} />
              
            </div>
          </div>
          <div className="col-md-6 boxx mt-3">
            <div className="cards">
              <h2>Issued Details</h2>
              <DataTable value={this.props.MappingData} 
                            scrollable responsive={true}
                            globalFilter={this.state.globalFilter} 
                            emptyMessage="No data found." sortMode="multiple" paginator={true}
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                            totalRecords={this.state.totalRecords}
                            lazy={true} first={this.state.first}
                            onPage={this.onPage} onSort={this.onSort}  ref={(el) => { this.dt = el; }}>
                <Column
                  field="ProductName"
                  header="Type"
                  sortable
                ></Column>
                <Column
                  field="FromNo"
                  header="From No"
                  sortable
                ></Column>
                <Column
                  field="ToNo"
                  header="To No"
                  sortable
                ></Column>
                <Column
                  field="IssueQty"
                  header="Quantity"
                  sortable
                ></Column>
                <Column
                  field="CreatedDate"
                  header="Issued Date"
                  sortable
                ></Column>
                {/* <Column field="expDate" header="Expiry Date" sortable></Column> */}
              </DataTable>
              {/*<Chart type="pie" data={chartdata} />*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DashboardList: state.dashboard?.items?.DashboardListData,
    TotalMapping: state.dashboard?.items?.DashboardCount?.TotalMapping,
    TotalScans: state.dashboard?.items?.DashboardCount?.TotalScans,
    TotalDeMapping: state.dashboard?.items?.DashboardCount?.TotalDeMapping,
    TotalDamage: state.dashboard?.items?.DashboardCount?.TotalDamage,
    TotalIssue: state.dashboard?.items?.DashboardCount?.TotalIssue,
    DeMappingChart: state.dashboard?.items?.DeMappingList,
    MappingChart: state.dashboard?.items?.MappingList,
    // FGChart: state.dashboard?.items?.FGList,
    Months: state.dashboard?.items?.MonthName,
    MappingData: state.issueNo.items,
    TotalProducts: state.productAuthentication?.prdctddl?.length
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dashboardActions.fetchData(obj));
    },
    fetchMappingApi: (obj) => {
      dispatch(issueNoActions.getAll(obj));
    },
    getPrdctDDL: () => {
      dispatch(productAuthenticationActions.getPrdctDDL())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
