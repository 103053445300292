export const mappingConstants = {
    CREATE_REQUEST: 'MAPPINGS_CREATE_REQUEST',
    CREATE_SUCCESS: 'MAPPINGS_CREATE_SUCCESS',
    CREATE_FAILURE: 'MAPPINGS_CREATE_FAILURE',

    UPDATE_REQUEST: 'MAPPINGS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MAPPINGS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MAPPINGS_UPDATE_FAILURE',

    GETALL_REQUEST: 'MAPPINGS_GETALL_REQUEST',
    GETALL_SUCCESS: 'MAPPINGS_GETALL_SUCCESS',
    GETALL_FAILURE: 'MAPPINGS_GETALL_FAILURE',

    DELETE_REQUEST: 'MAPPINGS_DELETE_REQUEST',
    DELETE_SUCCESS: 'MAPPINGS_DELETE_SUCCESS',
    DELETE_FAILURE: 'MAPPINGS_DELETE_FAILURE',
    
    GETALL_TONO_REQUEST: 'MAPPING_GETALL_TONO_REQUEST',
    GETALL_TONO_SUCCESS: 'MAPPING_GETALL_TONO_SUCCESS',
    GETALL_TONO_FAILURE: 'MAPPING_GETALL_TONO_FAILURE',

    DIALOG_REQUEST:'DIALOG_REQUEST',

    LOADING_REQUEST:'LOADING_REQUEST'
};