import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FileUpload } from "primereact/fileupload";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import VerifyProduct from "../models/VerifyProduct";
import Warranty from "../models/warranty";
import { Calendar } from "primereact/calendar";
import { productAuthenticationActions } from "../actions";
import axios from "axios";
import { handleResponse } from "../helpers";
import reviewIcon from "./../assets/images/write-review-icon.png";
import InstaIcon from "./../assets/images/instagram.png";
import OtherLinkIcon from "./../assets/images/link.png";
import PinterestIcon from "./../assets/images/pinterest.png";
import LinkedinIcon from "./../assets/images/linkedin.png";
import shareIcon from "./../assets/images/share-icon.png";
import sucessIcon from "./../assets/images/sucess-code.png";
import fakeIcon from "./../assets/images/wrong-code.png";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { Rating } from "primereact/rating";
import ReactPlayer from "react-player";
import { productMasterActions } from "../actions/productMasterActions";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import productimgpdf from "./../assets/images/warrnty_card.pdf";

export class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.clsVerifyProduct = new VerifyProduct();
    this.warrantyData = new Warranty();
    this.state = {
      isMobileNoFilled: false,
      formIsValid: false,
      selectedState: "",
      isButtonDisabled: false,
      timeLeft: 0,
      ShowDialogProduct: true,
      ShowDialogWarranty: false,
      ShowDialogService: false,
      IsLocation: false,
      ratingBox: false,
      socialLinks: false,
      shareBox: false,
      ProductId: 0,
      ProductName: "",
      VerifyProduct: {},
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      Name: "",
      email: "",
      address: "",
      MobileNo: "",
      Address: "",
      ProdPurchaseFrom: "",
      MobNo: "",
      InvoiceNo: "",
      Invoice: null,
      InvoiceDate: new Date(),
      UserFeedback: "",
      OTP: null,
      Pincode:null,
      showVideo: true,
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
      RequestError: {
        Name: "",
        address: "",
        MobileNo: "",
        UserFeedback: "",
        OTP: "",
        Pincode:"",
        productImage: "",
        warrantyimage: "",
        Invoice: "",
        selectedState: "",
      },
      ProdlistError: {
        ProductId: null,
      },
      WarrantylistError: {
        Name: null,
        email: null,
        MobNo: null,
        InvoiceNo: null,
        Invoice: null,
        ProdPurchaseFrom: null,
        Address: null,
        InvoiceDate: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.submitProductlist = this.submitProductlist.bind(this);
    this.submitWarrantylist = this.submitWarrantylist.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.submitRequestForm = this.submitRequestForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setImagePath = this.setImagePath.bind(this);
    this.setImagePath2 = this.setImagePath2.bind(this);
    this.setImagePath3 = this.setImagePath3.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openDialog1 = this.openDialog1.bind(this);
    this.openDialogg = this.openDialogg.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    //const curl = window.location.href;
    let userCollector = JSON.parse(sessionStorage.getItem("UserData"));
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.clsVerifyProduct.VerifiedBy = userCollector;
    this.findMe();
  }

  //get reverse geo code
  async reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    );
    return handleResponse(response);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }

  //to trigger api on page call
  componentDidMount() {
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }
    this.props.getPrdctDDL();
    var date = new Date();
    this.state.InvoiceDate = date.toLocaleDateString();
    // const urlParams = new URLSearchParams(window.location.search);
    // const parmUid = urlParams.get('uid');

    // const curl = window.location.href;

    // this.clsVerifyProduct.verificationMode = this.state.verificationMode;
    // this.clsVerifyProduct.qrData = this.state.qrData;
    // this.clsVerifyProduct.verificationType = this.state.verificationType;
    // this.clsVerifyProduct.UID = parmUid;

    // setTimeout(() => {
    //     // this.findMe();
    // }, 1000);
    // this.findMe();
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });

    this.props.verifyProduct(productAuthReq);
    this.props.GetWarranty(productAuthReq);
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // set Product Image Path
  setImagePath(event) {
    let errors = this.state.RequestError;
    errors.productImage = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              productImage: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.productImage = "Only image format is accepted.";
            this.setState({
              productImage: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  setImagePath2(event) {
    let errors = this.state.RequestError;
    errors.Invoice = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              Invoice: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.Invoice = "Only image format is accepted.";
            this.setState({
              Invoice: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }
  setImagePath3(event) {
    let errors = this.state.RequestError;
    errors.warrantyimage = "";
    if (event.xhr) {
      if (event.xhr.response) {
        const response = JSON.parse(event.xhr.response);
        if (response) {
          if (response.ResponseCode === "200") {
            this.setState({
              warrantyimage: response.Source,
            });
          } else if (response.ResponseCode === "400") {
            errors.warrantyimage = "Only image format is accepted.";
            this.setState({
              warrantyimage: "",
            });
          } else {
            // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
          }
        } else {
          //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
      } else {
        //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
      }
    } else {
      //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
    }
  }

  validateWarranty(value, name) {
    let errors = this.state.WarrantylistError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobNo = "Please enter valid mobile no.";
          } else errors.MobNo = null;
        } else errors.MobNo = "";
        break;
      case "InvoiceNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.InvoiceNo = "Please enter your Invoice no.";
        } else errors.InvoiceNo = "";
        break;

      case "Invoice":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Invoice = "Please select your Invoice.";
        } else errors.Invoice = "";
        break;
      case "Address":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Address = "Please enter your Address.";
        } else errors.Address = "";
        break;

      case "ProdPurchaseFrom":
        if (value.length < 1) {
          IsValid = false;
          errors.ProdPurchaseFrom =
            "Please enter from where you purchased product.";
        } else errors.ProdPurchaseFrom = "";
        break;

      case "InvoiceDate":
        let date = new Date();
        if (value === null || !(value instanceof Date)) {
          this.IsValid = false;
          errors.InvoiceDate = "Please Select Invoice Date";
          // this.refs.Messchild.showError("Please select from date.");
          return;
        } else if (value != null && value > date) {
          this.IsValid = false;
          errors.InvoiceDate =
            "Invoice Date Should not be Greater than Today's Date";
          // this.refs.Messchild.showError("Please select from date.");
          return;
        } else errors.InvoiceDate = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  validateProdlist(value, name) {
    let errors = this.state.ProdlistError;
    let IsValid = true;

    switch (name) {
      case "ProductId":
        if (value === 0 || value == undefined) {
          IsValid = false;
          errors.ProductId = "Please select Product";
        } else errors.ProductId = null;
        break;
    }

    return IsValid;
  }

  getDropValue() {
    let productId = "";
    this.props.ProductData.filter((x) => {
      if (x.ProductId === this.state.ProductId) {
        productId = x.ProductId;
      }
    });
    return productId;
  }

  submitForm(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let isfeedfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateFeedback(val, key);
      if (!valid) {
        this.setState({ checkerror: isfeedfrmvalid });
        isfeedfrmvalid = false;
      }

      return isfeedfrmvalid;
    });

    let VerId =
      this.props?.VerificationId != null
        ? this.props.VerificationId.ResponseData
        : this.props.ProductAuthenticationData.VerificationId;

    const feedbackData = {
      Name: this.state.Name,
      email: this.state.email,
      MobileNo: this.state.MobileNo,
      UserFeedback: this.state.UserFeedback,
      VerificationId: parseInt(VerId),
    };
    if (isfeedfrmvalid) {
      this.props.UpdateFeedback(feedbackData);
    }

    this.setState({ [feedbackData]: "" });
  }

  validateRequest(value, name) {
    let errors = this.state.RequestError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "OTP":
        if (!value || value.length !== 6) {
          IsValid = false;
          errors.OTP = "Please enter a 6-digit OTP.";
        } else if (value && value.length === 6) {
          if (!value.match(/^\d{6}$/)) {
            IsValid = false;
            errors.OTP = "OTP should contain only numbers.";
          } else errors.OTP = "";
        } else errors.OTP = "";
        break;
      case "productImage":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.productImage = "Please upload product image.";
        } else errors.productImage = "";
        break;
      case "Invoice":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.Invoice = "Please select your Invoice.";
        } else errors.Invoice = "";
        break;

      case "warrantyimage":
        if (!value || value.toString().length < 1) {
          IsValid = false;
          errors.warrantyimage = "Please select your Invoice.";
        } else errors.warrantyimage = "";
        break;
      case "selectedState":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.selectedState = "Please select State.";
        } else errors.selectedState = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your Comment.";
        } else errors.UserFeedback = "";
        break;
     case "Pincode":
        if (!value || value.length < 1) {
            IsValid = false;
            errors.Pincode = "Please enter your Pincode.";
        } else if (!/^\d+$/.test(value)) {
            // Checks if the value contains only digits
            IsValid = false;
            errors.Pincode = "Pincode must be numeric.";
        } else if (value.length !== 6) {
            // Assuming the pincode should be exactly 6 digits long
            IsValid = false;
            errors.Pincode = "Pincode must be 6 digits long.";
        } else {
            errors.Pincode = "";
        }
        break;
      default:
        break;
    }
    this.setState({ RequestError: errors });
    return IsValid;
  }
  submitRequestForm(event) {
    event.preventDefault();
    let isrequestfrmvalid = true;
    let checkerror = this.state.RequestError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateRequest(val, key);
      if (!valid) {
        this.setState({ checkerror: isrequestfrmvalid });
        isrequestfrmvalid = false;
      }
      return isrequestfrmvalid;
    });

    if (isrequestfrmvalid) {
      let VerId =
        this.props?.VerificationId != null
          ? this.props.VerificationId.ResponseData
          : this.props.ProductAuthenticationData.VerificationId;
 debugger
      const RequestData = {
        Name: this.state.Name,
        address: this.state.address,
        MobileNo: this.state.MobileNo,
        Remark: this.state.UserFeedback,
        OTP: this.state.OTP,
        Pincode:this.state.Pincode,
        VerificationId: parseInt(VerId),
        productImage: this.state.productImage,
        warrantyimage: this.state.warrantyimage,
        selectedState: this.state.selectedState,
        invoice: this.state.Invoice,
      };
      this.props.UpdateRequest(RequestData);
    }
  }

  submitWarrantylist(event) {
    // const { name, value } = event.target;
    event.preventDefault();
    let iswarrantyfrmvalid = true;
    let checkerror = this.state.WarrantylistError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateWarranty(val, key);
      if (!valid) {
        this.setState({ checkerror: iswarrantyfrmvalid });
        iswarrantyfrmvalid = false;
      }
      return iswarrantyfrmvalid;
    });
    let date = new Date(this.state.InvoiceDate);
    let dateMDY = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let Name =
      this.props?.SelectedProduct != null
        ? this.props.SelectedProduct.ProductName
        : this.props.ProductAuthenticationData.ProductName;
    let ProdId =
      this.props?.SelectedProduct != null
        ? this.props.SelectedProduct.ProductId
        : this.props.ProductAuthenticationData.ProductId;
    const formData = new FormData();
    formData.append("file", this.state.Invoice);
    formData.append("ProductId", ProdId);
    formData.append("ProductName", Name);
    formData.append("CustomerName", this.state.Name);
    formData.append("QrData", this.state.QrData);
    formData.append("Email", this.state.email);
    formData.append("MobNo", this.state.MobNo);
    formData.append("InvoiceNo", this.state.InvoiceNo);
    formData.append("InvoiceDate", dateMDY);
    formData.append("Address", this.state.Address);
    formData.append("ProdPurchaseFrom", this.state.ProdPurchaseFrom);
    if (iswarrantyfrmvalid) {
      this.props.CreateWarrantyForm(formData);
      this.warrantyData.QrData = this.state.QrData;
      this.warrantyData.ProductId = ProdId;
      this.props.GetWarranty(this.warrantyData);
      this.state.ShowDialogWarranty = false;
    }
  }

  submitProductlist(event) {
    // const { name, value } = event.target;
    event.preventDefault();
    let isprodlistvalid = true;
    let checkerror = this.state.ProdlistError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateProdlist(val, key);
      if (!valid) {
        this.setState({ checkerror: isprodlistvalid });
        isprodlistvalid = false;
      }

      return isprodlistvalid;
    });

    const ProdlistData = {
      ProductId: parseInt(this.state.ProductId),
      QrData: this.state.QrData,
    };

    this.warrantyData.QrData = this.state.QrData;
    this.warrantyData.ProductId = ProdlistData.ProductId;
    this.clsVerifyProduct.ProductId = ProdlistData.ProductId;

    if (isprodlistvalid) {
      this.props.GetProduct(ProdlistData);
      this.props.GetWarranty(this.warrantyData);
      this.props.insertVerification(this.clsVerifyProduct);
      this.state.ShowDialogProduct = false;
    }

    this.setState({ [ProdlistData]: "" });
    //console.log("Prod", this.props.SelectedProduct);
  }

  RequestReset() {
    this.setState({
      Name: "",
      address: "",
      MobileNo: "",
      OTP: "",
      UserFeedback: "",
      Invoice: "",
      warrantyimage: "",
      selectedState: "",
      productImage: "",
      RequestError: {
        Name: "",
        address: "",
        MobileNo: "",
        UserFeedback: "",
        OTP: "",
        productImage: "",
        warrantyimage: "",
        Invoice: "",
        selectedState: "",
      },
    });
    let errors = this.state.RequestError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }
  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  WarrantyFormReset() {
    this.setState({
      Name: "",
      email: "",
      MobNo: "",
      InvoiceNo: "",
      ProdPurchaseFrom: "",
      Address: "",
      Invoice: "",
      WarrantylistError: {
        Name: null,
        email: null,
        MobNo: null,
        InvoiceNo: null,
        Invoice: null,
        ProdPurchaseFrom: null,
        Address: null,
        InvoiceDate: null,
      },
    });
    let errors = this.state.WarrantylistError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  ProdlistReset() {
    this.setState({
      ProductId: 0,
      ProductData: "",
      ProdlistError: {
        ProductId: "",
      },
    });
    let errors = this.state.ProdlistError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  updateRating(productId, verificationId, event) {
    this.setState({ rating: event.value });

    var data = {
      ProductId: productId,
      ProductRating: event.value,
      VerificationId: verificationId,
    };

    if (productId) {
      this.props.updateRating(data);
    } else {
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.validateRequest(value, name);
    this.validateProdlist(value, name);
    this.validateWarranty(value, name);
    this.setState({ errors, [name]: value });
  }

  // handleChange1 = (event) => {
  //     const { name, value } = event.target;
  //     this.setState({ [name]: value });

  //     // Check if the mobile number is complete (assuming 10 digits for this example)
  //     if (name === 'MobileNo' && value.length === 10) {
  //         this.setState({ isMobileNoFilled: true });
  //         this.CallApi(value);
  //     } else {
  //         this.setState({ isMobileNoFilled: false });
  //     }
  // };
  SendOtp = async (value) => {
    let errors = this.state.RequestError;
    let IsValid = true;
    if (!value || value.length !== 10) {
      IsValid = false;
      errors.MobileNo = "Pls Enter Mobile No.";
      this.setState({ RequestError: errors });
      return IsValid;
    }

    try {
      // Make API call
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}ProductAuthentication/OTP?mob=${value}`
      );
      //console.log("API response:", response.data);

      if (response.data.ResponseCode === "200") {
        // Set state to start the timer if the response is successful
        this.setState(
          { isButtonDisabled: true, timeLeft: 120 },
          this.startTimer
        );
      } else {
        // Handle API response errors
        console.error(
          "API error:",
          response.data.ResponseMessage || "Unknown error"
        );
      }
    } catch (error) {
      console.error("There was an error calling the API!", error);
    }
  };

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeLeft <= 1) {
          clearInterval(this.timer);
          return { timeLeft: 0, isButtonDisabled: false };
        }
        return { timeLeft: prevState.timeLeft - 1 };
      });
    }, 1000);
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  openDialog() {
    this.props.onHideShow(true);
  }

  onHide() {
    this.props.onHideShow(false);
  }

  openDialog1() {
    this.RequestReset();
    this.props.onHideShow1(true);
  }

  openDialogg() {
    this.props.onHideShow(true);
  }

  onProductHide() {
    this.props.onProductHideShow(false);
  }

  onWarrantyHide() {
    //this.props.onWarrantyHideShow(false);
  }

  async printDocument() {
    //const existingPdfBytes = productimgpdf;
    // (async () => {

    this.warrantyData.QrData = this.state.QrData;
    //this.warrantyData.ProductId = this.props.SelectedProduct.ProductId;
    this.props.GetWarranty(this.warrantyData);
    const url = productimgpdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();

    let tmpdate = this.props.Warranty[0].InvoiceDate.split("T");

    // Draw a string of text diagonally across the first page

    firstPage.drawText(
      this.props.Warranty[0].InvoiceNo == null ||
        this.props.Warranty[0].InvoiceNo == undefined
        ? ""
        : this.props.Warranty[0].InvoiceNo,
      {
        x: 200,
        y: 526,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].CustomerName == null ||
        this.props.Warranty[0].CustomerName == undefined
        ? ""
        : this.props.Warranty[0].CustomerName,
      {
        x: 267,
        y: 471,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    let tmpAddress = this.props.Warranty[0].Address;
    const array = new Array(tmpAddress.split(/\s+/));
    let addLine1 = "";
    let addLine2 = "";
    let length = array["0"].length;
    for (let i = 0; i < length; i++) {
      if (i < length / 2) {
        addLine1 = addLine1 + " " + array["0"][i];
      } else {
        addLine2 = addLine2 + " " + array["0"][i];
      }
    }
    //console.log("addLine1", addLine1);
    //console.log("addLine2", addLine2);

    firstPage.drawText(
      this.props.Warranty[0].Address == null ||
        this.props.Warranty[0].Address == undefined
        ? ""
        : addLine1,
      {
        x: 170,
        y: 419,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].Address == null ||
        this.props.Warranty[0].Address == undefined
        ? ""
        : addLine2,
      {
        x: 90,
        y: 369,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].MobNo == null ||
        this.props.Warranty[0].MobNo == undefined
        ? ""
        : this.props.Warranty[0].MobNo,
      {
        x: 195,
        y: 269,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    let Name =
      this.props?.SelectedProduct != null
        ? this.props.SelectedProduct.ProductName
        : this.props.ProductAuthenticationData.ProductName;

    firstPage.drawText(
      this.props.Warranty[0].ProductName == null ||
        this.props.Warranty[0].ProductName == undefined
        ? ""
        : this.props.Warranty[0].ProductName,
      {
        x: 300,
        y: 219,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].PurchaseDate == null ||
        this.props.Warranty[0].PurchaseDate == undefined
        ? ""
        : this.props.Warranty[0].PurchaseDate,
      {
        x: 265,
        y: 169,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].ExpireDate == null ||
        this.props.Warranty[0].ExpireDate == undefined
        ? ""
        : this.props.Warranty[0].ExpireDate,
      {
        x: 235,
        y: 117,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      this.props.Warranty[0].ProdPurchaseFrom == null ||
        this.props.Warranty[0].ProdPurchaseFrom == undefined
        ? ""
        : this.props.Warranty[0].ProdPurchaseFrom,
      {
        x: 320,
        y: 68,
        size: 11,
        font: helveticaFont,
        size: 15,
        color: rgb(0, 0, 0),
      }
    );

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();
    //FileSaver.saveAs(pdfBytes, "pdf-lib_modification_example.pdf");
    const url1 = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", "Certificate.pdf");
    document.body.appendChild(link);
    link.click();

    //return this.download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
    // })();
  }

  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          // alert(JSON.stringify(denied));
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.UserCity = res.address.state_district;
        this.clsVerifyProduct.UserState = res.address.state;
        this.clsVerifyProduct.UserCntry = res.address.country;
        this.clsVerifyProduct.UserZip = res.address.postcode;
        this.clsVerifyProduct.UserAddress = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        // alert(JSON.stringify(error))
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }

  handleFileUpload(event) {
    let image_as_files = event.target.files[0];
    this.state.Invoice = image_as_files;
  }

  handleOnReady = () => setTimeout(() => this.setState({ playing: true }), 100);

  render() {
    let links = null;
    let share = null;
    return (
      <section className="">
        <div className="pa-outer row wapperbgColor">
          {this.props.ProductAuthenticationData != null &&
          this.props.ProductAuthenticationData.ResponseCode == "200" ? (
            <div className="container">
              <Dialog
                visible={false}
                onHide={() => this.setState({ showVideo: false })}
              >
                {/* <ReactPlayer class="video"  url='https://www.youtube.com/embed/AZ6iUjLZhxw' playing={true} /> */}
                {/* <video controls ref="videoFile">
                                <source src={this.props.ProductAuthenticationData.VideoFile} />
                            </video> */}
                {/* <iframe width="420" height="300"
                                src={this.props.ProductAuthenticationData.VideoFile} allowFullScreen={true}>
                            </iframe> */}

                {/* <video width="420" height="300" controls autoplay name="media">
                                <source src={this.props.ProductAuthenticationData.VideoFile} />

                            </video> */}

                <ReactPlayer
                  className="youtube-video"
                  url={
                    this.props.SelectedProduct == null
                      ? this.props.ProductAuthenticationData.VideoFile
                      : this.props.SelectedProduct.VideoFile
                  }
                  playing={true}
                  controls={true}
                />
              </Dialog>

              {/*this.props.ProductAuthenticationData.isMapped == 0 ? 
                                <span>
                                    {this.props.SelectedProduct == null ? "" : <audio controls autoPlay src={this.props.SelectedProduct.GenuineAudio} id="pm3paly" hidden /> }
                                </span>
                            :   <span>
                                    {this.props.ProductAuthenticationData.isMapped === 1 ? <audio controls autoPlay src={this.props.ProductAuthenticationData.GenuineAudio} id="pm3paly" /> : "" }
                                </span>*/}

              {/*

                            <ReactPlayer controls={true} volume={10} onReady={this.handleOnReady} playing={false} url={this.props.ProductAuthenticationData.GenuineAudio} />
                            
                            <ReactPlayer
                                
                                url="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
                                width="400px"
                                height="50px"
                                onReady={this.handleOnReady}
                                playing={false}
                                controls={true}
                            />*/}

              {(this.props.ProductAuthenticationData.GenuineAudio != null) ===
              true ? (
                <audio
                  controls
                  hidden
                  autoPlay
                  src={
                    this.props.SelectedProduct == null
                      ? this.props.ProductAuthenticationData.GenuineAudio
                      : this.props.SelectedProduct.GenuineAudio
                  }
                  id="pm3paly"
                />
              ) : (
                ""
              )}

              <span>
                {this.props.SelectedProduct != null &&
                this.props.SelectedProduct.ResponseCode == "-1" ? (
                  <div className="success-message">
                    <h2>
                      <img src={fakeIcon} />{" "}
                      {this.props.SelectedProduct.ResponseMessage}
                    </h2>
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      onClick={() => this.openDialog()}
                    >
                      Feedback
                    </button>
                    <br></br>
                    {/* <button type="button" className="btn btn-primary" onClick={this.openDialog}>
                                            <span className="fa fa-cogs"> Service Request</span>
                                        </button> */}
                  </div>
                ) : (
                  <div className="success-message">
                    <h2 className="genuine-product">
                      <img src={sucessIcon} />{" "}
                      {this.props.SelectedProduct == null
                        ? this.props.ProductAuthenticationData.ResponseMessage
                        : this.props.SelectedProduct.GenuineMessage}
                    </h2>
                  </div>
                )}
              </span>

              <span>
                {this.props.SelectedProduct != null &&
                this.props.SelectedProduct.ResponseCode == "-1" ? (
                  ""
                ) : (
                  <div
                    id="testId"
                    className="text-center image-wraper col-md-12"
                  >
                    <div className="logo-wraper">
                      <div className="logo-c">
                        <img
                          src={this.props.ProductAuthenticationData.CompanyLogo}
                        />
                      </div>
                    </div>
                    <img
                      width="330px"
                      height="300px"
                      src={
                        this.props.SelectedProduct == null
                          ? this.props.ProductAuthenticationData.ProductImage
                          : this.props.SelectedProduct.ProductImage
                      }
                    />
                  </div>
                )}
              </span>

              <span>
                {this.props.SelectedProduct != null &&
                this.props.SelectedProduct.ResponseCode == "-1" ? (
                  ""
                ) : (
                  <>
                    <div className="col-md-6"></div>
                    <div className="product-detail">
                      <h3>
                        {this.props.SelectedProduct == null
                          ? this.props.ProductAuthenticationData.ProductName
                          : this.props.SelectedProduct.ProductName}
                      </h3>

                      {this.props.ProductAuthenticationData.isMapped == 0 ? (
                        <span>
                          {this.props?.SelectedProduct == null ? (
                            <span>
                              {" "}
                              {this.props.ProductAuthenticationData.IsRating !=
                                null &&
                              this.props.ProductAuthenticationData.IsRating !=
                                "0" ? (
                                <span
                                  onClick={() =>
                                    this.setState({
                                      ratingBox: !this.state.ratingBox,
                                    })
                                  }
                                >
                                  <i className="fa fa-star"></i>
                                  {this.props.Rating &&
                                  this.props.Rating.ProductRating
                                    ? this.props.Rating.ProductRating
                                    : this.props.ProductAuthenticationData
                                        .ProductRating}
                                  (
                                  {this.props.Rating &&
                                  this.props.Rating.TotalRating
                                    ? this.props.Rating.TotalRating
                                    : this.props.ProductAuthenticationData
                                        .TotalRating == null
                                    ? 0
                                    : this.props.ProductAuthenticationData
                                        .TotalRating}
                                  )
                                </span>
                              ) : null}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              {this.props.SelectedProduct.IsRating != null &&
                              this.props.SelectedProduct.IsRating != "0" ? (
                                <span
                                  onClick={() =>
                                    this.setState({
                                      ratingBox: !this.state.ratingBox,
                                    })
                                  }
                                >
                                  <i className="fa fa-star"></i>
                                  {this.props.Rating &&
                                  this.props.Rating.ProductRating
                                    ? this.props.Rating.ProductRating
                                    : this.props.SelectedProduct.ProductRating}
                                  (
                                  {this.props.Rating &&
                                  this.props.Rating.TotalRating
                                    ? this.props.Rating.TotalRating
                                    : this.props.SelectedProduct.TotalRating ==
                                      null
                                    ? 0
                                    : this.props.SelectedProduct.TotalRating}
                                  )
                                </span>
                              ) : null}
                              {this.state.ratingBox ? (
                                <div className="rating-wraper">
                                  {this.props.ProductAuthenticationData
                                    .isRated == 0 ? (
                                    <Rating
                                      cancel={false}
                                      value={this.state.rating}
                                      onChange={(e) =>
                                        this.updateRating(
                                          this.props.SelectedProduct.ProductId,
                                          this.props.VerificationId
                                            .ResponseData,
                                          e
                                        )
                                      }
                                    />
                                  ) : (
                                    <Rating
                                      cancel={false}
                                      readonly
                                      value={
                                        this.props.SelectedProduct.ProductRating
                                      }
                                      onChange={(e) =>
                                        this.updateRating(
                                          this.props.ProductAuthenticationData
                                            .ProductId,
                                          this.props.ProductAuthenticationData
                                            .VerificationId,
                                          e
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              ) : null}
                            </span>
                          )}
                        </span>
                      ) : null}

                      {this.props.ProductAuthenticationData.isMapped == 1 ? (
                        <span>
                          {this.props.ProductAuthenticationData.IsRating !=
                            null &&
                          this.props.ProductAuthenticationData.IsRating !=
                            "0" ? (
                            <span
                              onClick={() =>
                                this.setState({
                                  ratingBox: !this.state.ratingBox,
                                })
                              }
                            >
                              <i className="fa fa-star"></i>
                              {this.props.Rating &&
                              this.props.Rating.ProductRating
                                ? this.props.Rating.ProductRating
                                : this.props.ProductAuthenticationData
                                    .ProductRating}
                              (
                              {this.props.Rating &&
                              this.props.Rating.TotalRating
                                ? this.props.Rating.TotalRating
                                : this.props.ProductAuthenticationData
                                    .TotalRating == null
                                ? 0
                                : this.props.ProductAuthenticationData
                                    .TotalRating}
                              )
                            </span>
                          ) : null}

                          {this.state.ratingBox ? (
                            <div className="rating-wraper">
                              {this.props.ProductAuthenticationData.isRated ==
                              0 ? (
                                <Rating
                                  cancel={false}
                                  value={this.state.rating}
                                  onChange={(e) =>
                                    this.updateRating(
                                      this.props.ProductAuthenticationData
                                        .ProductId,
                                      this.props.ProductAuthenticationData
                                        .VerificationId,
                                      e
                                    )
                                  }
                                />
                              ) : (
                                <Rating
                                  cancel={false}
                                  readonly
                                  value={
                                    this.props.ProductAuthenticationData
                                      .ProductRating
                                  }
                                  onChange={(e) =>
                                    this.updateRating(
                                      this.props.ProductAuthenticationData
                                        .ProductId,
                                      this.props.ProductAuthenticationData
                                        .VerificationId,
                                      e
                                    )
                                  }
                                />
                              )}
                            </div>
                          ) : null}
                        </span>
                      ) : null}

                      {this.props.ProductAuthenticationData.IsFeedback !=
                        null &&
                      this.props.ProductAuthenticationData.IsFeedback != "0" ? (
                        <span onClick={() => this.openDialog()}>
                          <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                          Feedback
                        </span>
                      ) : null}

                      {this.props.ProductAuthenticationData != null &&
                      this.props.ProductAuthenticationData.SocialLinks !=
                        null ? (
                        <span
                          onClick={() =>
                            this.setState({
                              socialLinks: !this.state.socialLinks,
                            })
                          }
                        >
                          <i class="fa fa-link" aria-hidden="true"></i>
                          Social Links
                        </span>
                      ) : null}

                      {this.state.socialLinks ? (
                        <div className="share-wraper">
                          <span>
                            {
                              ((links = JSON.parse(
                                this.props.ProductAuthenticationData.SocialLinks
                              )),
                              this.props.ProductAuthenticationData != null &&
                              this.props.ProductAuthenticationData
                                .SocialLinks != null ? (
                                <>
                                  <span className="col-md-6 pb-3">
                                    {Object.keys(links).map((x) => {
                                      switch (x) {
                                        case "Facebook": {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <FacebookIcon size={27} round />
                                              </a>
                                            </span>
                                          );
                                        }
                                        case "Instagram": {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <img
                                                  width="27px"
                                                  height="27px"
                                                  src={InstaIcon}
                                                ></img>
                                              </a>
                                            </span>
                                          );
                                        }
                                        case "LinkedIn": {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <img
                                                  width="27px"
                                                  height="27px"
                                                  src={LinkedinIcon}
                                                ></img>
                                              </a>
                                            </span>
                                          );
                                        }
                                        case "Twitter": {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <img
                                                  width="27px"
                                                  height="27px"
                                                  src={TwitterIcon}
                                                ></img>
                                              </a>
                                            </span>
                                          );
                                        }
                                        case "Pinterest": {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <img
                                                  width="27px"
                                                  height="27px"
                                                  src={PinterestIcon}
                                                ></img>
                                              </a>
                                            </span>
                                          );
                                        }
                                        default: {
                                          return (
                                            <span className="mr-2">
                                              <a
                                                href={links[x]}
                                                target="_blank"
                                              >
                                                <img
                                                  width="27px"
                                                  height="27px"
                                                  src={OtherLinkIcon}
                                                ></img>
                                              </a>
                                            </span>
                                          );
                                        }
                                      }
                                    })}
                                  </span>
                                </>
                              ) : (
                                ""
                              ))
                            }
                          </span>
                        </div>
                      ) : null}

                      {this.props.ProductAuthenticationData.isMapped != 0 ? (
                        <span>
                          {this.props.ProductAuthenticationData.IsShare !=
                            null &&
                          this.props.ProductAuthenticationData.IsShare !=
                            "0" ? (
                            <span
                              onClick={() =>
                                this.setState({
                                  shareBox: !this.state.shareBox,
                                })
                              }
                            >
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                              Share
                            </span>
                          ) : null}
                        </span>
                      ) : (
                        <span>
                          {this.props.SelectedProduct != null &&
                          this.props.SelectedProduct.IsShare != null &&
                          this.props.SelectedProduct.IsShare != "0" ? (
                            <span
                              onClick={() =>
                                this.setState({
                                  shareBox: !this.state.shareBox,
                                })
                              }
                            >
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                              Share
                            </span>
                          ) : null}
                        </span>
                      )}

                      {
                        ((share =
                          this.props.ProductAuthenticationData.isMapped != 0
                            ? this.props.ProductAuthenticationData.ShareURL
                            : this.props.SelectedProduct != null
                            ? this.props.SelectedProduct.ShareURL
                            : ""),
                        this.state.shareBox ? (
                          <div className="share-wraper">
                            <div className="Demo__some-network">
                              <FacebookShareButton
                                url={share}
                                quote={share}
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </div>
                            <div className="Demo__some-network">
                              <TwitterShareButton
                                url={share}
                                quote={share}
                                className="Demo__some-network__share-button"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                            </div>
                            <div className="Demo__some-network">
                              <WhatsappShareButton
                                url={share}
                                quote={share}
                                className="Demo__some-network__share-button"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </div>
                            <div className="Demo__some-network">
                              <LinkedinShareButton
                                url={share}
                                quote={share}
                                className="Demo__some-network__share-button"
                              >
                                <LinkedinIcon size={32} round />
                              </LinkedinShareButton>
                            </div>
                            <div className="Demo__some-network">
                              <EmailShareButton
                                url={share}
                                quote={share}
                                className="Demo__some-network__share-button"
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </div>

                            {/* <i className="fa fa-facebook-square"></i>
                                <i className="fa fa-twitter-square"></i>
                                <i className="fa fa-youtube-square"></i>
                                <i className="fa fa-google-plus-square"></i> */}
                          </div>
                        ) : null)
                      }

                      {this.props.isWarrantyRegistered == false ? (
                        <div
                          className="warranty-card"
                          onClick={this.printDocument}
                        >
                          <img src="https://img.icons8.com/material-outlined/24/000000/download--v1.png" />
                          Warranty Card
                        </div>
                      ) : null}
                      <div
                        className="tarun d-flex justify-content-end"
                        style={{ padding: " 0px" }}
                      >
                        <Button
                          label="Service Request"
                          icon="fa fa-cogs"
                          style={{ padding: "0px", fontSize: "12px" }}
                          onClick={() => this.openDialog1()}
                          type="button"
                        />
                      </div>

                      {/* <span className='tarun d-flex justify-content-end' onClick={() => this.openDialog1()}>
                                                <i className="fa fa-cogs" aria-hidden="true" , background: '#001e57' }}>
                                                </i>
                                            </span> */}
                    </div>
                    <div className="row mt-4 ml-5"></div>
                  </>
                )}
              </span>

              {(this.props.SelectedProduct != null &&
                this.props.SelectedProduct.ResponseCode != "-1") ||
              this.props.ProductAuthenticationData.isMapped == 1 ? (
                <div className="row mt-4 ml-5 mr-5">
                  <>
                    <div className="col-md-6 pb-3 row_data">Product Name:</div>
                    <div className="col-md-6 pb-3 row_data1">
                      {this.props.ProductAuthenticationData.isMapped == 1
                        ? this.props.ProductAuthenticationData.ProductName
                        : this.props.SelectedProduct.ProductName}
                    </div>
                  </>

                  {this.props.ProductAuthenticationData.Warranty == 1 &&
                  this.props.ProductAuthenticationData.isMapped == 0 ? (
                    <>
                      <div
                        className="col-md-6 pb-3 row_data"
                        style={{ paddingTop: "12px" }}
                      >
                        Warranty
                      </div>
                      <div className="col-md-6 pb-3 row_data1">
                        {this.props.SelectedProduct.Warranty > 0 ? (
                          <div>
                            {" "}
                            {this.props.SelectedProduct.Warranty} Month(s)
                            {this.props.isWarrantyRegistered == true ? (
                              <span
                                style={{
                                  marginLeft: "12px",
                                  verticalAlign: "sub",
                                }}
                                onClick={() => {
                                  this.WarrantyFormReset();
                                  this.setState({ ShowDialogWarranty: true });
                                }}
                              >
                                <Button
                                  hidden={
                                    this.props.isWarrantyRegistered == true
                                      ? false
                                      : true
                                  }
                                >
                                  Register Warranty
                                </Button>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          "Warranty Not Available"
                        )}

                        {/* this.props.SelectedProduct.Warranty > 0 ? <a href="">Register Warranty</a> : "Warranty Not Available"
                                                    this.props.SelectedProduct.Warranty > 0 ? <span>{this.props.SelectedProduct.Warranty} Month(s) <a href="">(Click Here to Register Warranty)</a> </span> : "Warranty Not Available"
                                                } */}
                      </div>
                    </>
                  ) : null}

                  {this.props.ProductAuthenticationData.isMapped == 1 &&
                  this.props.ProductAuthenticationData.Warranty > 0 &&
                  this.props.ProductAuthenticationData.isWarranty == 1 ? (
                    <>
                      <div
                        className="col-md-6 pb-3 row_data"
                        style={{ paddingTop: "12px" }}
                      >
                        Warranty
                      </div>
                      <div className="col-md-6 pb-3 row_data1">
                        {this.props.ProductAuthenticationData.Warranty > 0 ? (
                          <div>
                            {" "}
                            {this.props.ProductAuthenticationData.Warranty}{" "}
                            Month(s)
                            {this.props.isWarrantyRegistered == true ? (
                              <span
                                style={{
                                  marginLeft: "12px",
                                  verticalAlign: "sub",
                                }}
                                onClick={() => {
                                  this.WarrantyFormReset();
                                  this.setState({ ShowDialogWarranty: true });
                                }}
                              >
                                <Button
                                  hidden={
                                    this.props.isWarrantyRegistered == true
                                      ? false
                                      : true
                                  }
                                >
                                  Register Warranty
                                </Button>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          "Warranty Not Available"
                        )}

                        {/* this.props.SelectedProduct.Warranty > 0 ? <a href="">Register Warranty</a> : "Warranty Not Available"
                                                    this.props.SelectedProduct.Warranty > 0 ? <span>{this.props.SelectedProduct.Warranty} Month(s) <a href="">(Click Here to Register Warranty)</a> </span> : "Warranty Not Available"
                                                } */}
                      </div>
                    </>
                  ) : null}

                  {this.props.ProductAuthenticationData.isMapped == 1
                    ? this.props.ProductAuthenticationData.ProductSpecification.map(
                        (x, i) => {
                          if (x.Key == "Website") {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  {x.Key}:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  <a href={x.Value} target="_blank">
                                    {x.Value}{" "}
                                  </a>
                                </div>
                              </>
                            );
                          } else if (x.Key == "Mobile") {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  Helpline No.:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  {x.Value}
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  {x.Key}:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  {x.Value}
                                </div>
                              </>
                            );
                          }
                        }
                      )
                    : null}

                  {this.props.ProductAuthenticationData.isMapped == 0 &&
                  this.props.SelectedProduct.ProductSpecification != null
                    ? this.props.SelectedProduct.ProductSpecification.map(
                        (x, i) => {
                          if (x.Key == "Website") {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  {x.Key}:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  <a href={x.Value} target="_blank">
                                    {x.Value}{" "}
                                  </a>
                                </div>
                              </>
                            );
                          } else if (x.Key == "Mobile") {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  Helpline No.:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  {x.Value}
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className="col-md-6 pb-3 row_data">
                                  {x.Key}:
                                </div>

                                <div className="col-md-6 pb-3 row_data1">
                                  {x.Value}
                                </div>
                              </>
                            );
                          }
                        }
                      )
                    : null}
                </div>
              ) : null}
            </div>
          ) : (
            // <div className="container-fluid p-0">{
            //     (this.props.ProductAuthenticationData != null && this.props.ProductAuthenticationData.FakeAudio) === true
            //         ?
            //         <audio controls autoPlay src={this.props.ProductAuthenticationData.FakeAudio} id="pm3paly" hidden />
            //         : ""}

            //     <div className="success-message">
            //         {
            //             this.props.ProductAuthenticationData != null ?
            //                 <div className="text-center p-3"> <img src={fakeIcon} className="mb-3" />
            //                     <h2 className="mb-4">{this.props.ProductAuthenticationData.ResponseMessage}</h2>
            //                 </div>
            //                 : ""
            //         }
            //     </div>
            // </div>

            <div className="container-fluid p-0">
              {(this.props.ProductAuthenticationData != null &&
                this.props.ProductAuthenticationData.FakeAudio) === true ? (
                <audio
                  controls
                  autoPlay
                  src={this.props.ProductAuthenticationData.FakeAudio}
                  id="pm3paly"
                  hidden
                />
              ) : (
                ""
              )}

              <div className="success-message box">
                {this.props.ProductAuthenticationData != null ? (
                  <div className="text-center p-3">
                    {" "}
                    <img src={fakeIcon} className="mb-3" />
                    <h2 className="mb-4">
                      {this.props.ProductAuthenticationData.ResponseMessage}
                    </h2>
                  </div>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => this.openDialog()}
                >
                  Feedback
                </button>
              </div>
            </div>
          )}
          ;
          {this.props.ProductAuthenticationData != null &&
          this.props.ProductAuthenticationData.ResponseCode == "200" &&
          this.props.ProductAuthenticationData.isMapped == 0 ? (
            <Dialog
              header="Select Product"
              modal
              dismissableMask={true}
              visible={this.state.ShowDialogProduct}
              className="popup3"
              onHide={this.onHide}
            >
              <form onSubmit={this.submitProductlist}>
                <div className="row ">
                  <div className="col-md-11 pt-2">
                    <FormControl
                      variant="outlined"
                      size="small"
                      className="formControl col-md-12"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select Product*
                      </InputLabel>
                      <Select
                        onChange={this.handleChange}
                        name="ProductId"
                        value={this.state.ProductId}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Select Product"
                      >
                        <MenuItem>
                          <em>None</em>
                        </MenuItem>
                        {(typeof this.props.ProductData == "undefined" ||
                          this.props.ProductData == null) === true
                          ? null
                          : this.props.ProductData?.map((key) => (
                              <MenuItem value={key.value}>{key.label}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                    {this.state.ProdlistError.ProductId !== null ? (
                      <div className="error">
                        {this.state.ProdlistError.ProductId}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 mt-3 text-center">
                    <Button label="Submit" icon="pi pi-check" />
                  </div>
                </div>
              </form>
            </Dialog>
          ) : (
            ""
          )}
          <Dialog
            header="Warranty Form"
            modal
            dismissableMask={true}
            visible={this.state.ShowDialogWarranty}
            className="popup"
            onHide={() => this.setState({ ShowDialogWarranty: false })}
          >
            <form onSubmit={this.submitWarrantylist}>
              <div className="row">
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Name"
                    value={this.state.Name}
                    className="fullwidth"
                    label="Name*"
                  />
                  {this.state.WarrantylistError.Name !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.Name}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="email"
                    value={this.state.email}
                    className="fullwidth"
                    label="Email*"
                  />
                  {this.state.WarrantylistError.email !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.email}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="MobNo"
                    value={this.state.MobNo}
                    className="fullwidth"
                    label="Mobile number*"
                  />
                  {this.state.WarrantylistError.MobNo !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.MobNo}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Address"
                    value={this.state.Address}
                    className="fullwidth"
                    label="Address*"
                  />
                  {this.state.WarrantylistError.Address !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.Address}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="InvoiceNo"
                    value={this.state.InvoiceNo}
                    className="fullwidth"
                    label="Invoice Number*"
                  />
                  {this.state.WarrantylistError.InvoiceNo !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.InvoiceNo}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <span>Invoice: </span>
                  <input
                    type="file"
                    name="Invoice"
                    onChange={(event) => {
                      this.setState({ Invoice: event.target.files[0] });
                    }}
                  ></input>

                  {this.state.WarrantylistError.Invoice !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.Invoice}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="ProdPurchaseFrom"
                    value={this.state.ProdPurchaseFrom}
                    className="fullwidth"
                    label="ProdPurchaseFrom*"
                  />
                  {this.state.WarrantylistError.ProdPurchaseFrom !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.ProdPurchaseFrom}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <Calendar
                    placeholder="Invoice Date"
                    appendTo={document.body}
                    name="InvoiceDate"
                    showIcon="true"
                    value={this.state.InvoiceDate}
                    onChange={this.handleChange}
                  ></Calendar>
                  {this.state.WarrantylistError.InvoiceDate !== "" ? (
                    <div className="error">
                      {this.state.WarrantylistError.InvoiceDate}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-12 mt-3 text-center">
                  <Button label="Submit" icon="pi pi-check" />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            header="Feedback Form"
            modal
            dismissableMask={true}
            visible={this.props.ShowDialogFeedback}
            className="popup"
            onHide={this.onHide}
          >
            <form onSubmit={this.submitForm}>
              <div className="row mt-3">
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Name"
                    value={this.state.Name}
                    className="fullwidth"
                    label="Name*"
                  />
                  {this.state.formError.Name !== "" ? (
                    <div className="error">{this.state.formError.Name}</div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="email"
                    value={this.state.email}
                    className="fullwidth"
                    label="Email*"
                  />
                  {this.state.formError.email !== "" ? (
                    <div className="error">{this.state.formError.email}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="MobileNo"
                    value={this.state.MobileNo}
                    className="fullwidth"
                    label="Mobile number*"
                  />
                  {this.state.formError.MobileNo !== "" ? (
                    <div className="error">{this.state.formError.MobileNo}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="UserFeedback"
                    value={this.state.UserFeedback}
                    className="fullwidth"
                    label="Feedback*"
                  />
                  {this.state.formError.UserFeedback !== "" ? (
                    <div className="error">
                      {this.state.formError.UserFeedback}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12 mt-3 text-center">
                  <Button label="Submit" icon="pi pi-check" />
                </div>
              </div>
            </form>
          </Dialog>
          <Dialog
            header="Service Request"
            modal
            dismissableMask={true}
            visible={this.props.ShowDialogService}
            className="popup"
            onHide={() => {
              this.props.onHideShow1(false);
              this.RequestReset();
            }}
          >
            <form onSubmit={this.submitRequestForm}>
              <div className="row mt-3">
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Name"
                    value={this.state.Name}
                    className="fullwidth"
                    label="Name*"
                  />
                  {this.state.RequestError.Name !== "" ? (
                    <div className="error mt-2">
                      {this.state.RequestError.Name}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="address"
                    value={this.state.address}
                    className="fullwidth"
                    label="Address"
                  />
                  {this.state.RequestError.address !== "" ? (
                    <div className="error  mt-2">
                      {this.state.RequestError.address}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="MobileNo"
                    value={this.state.MobileNo}
                    className="fullwidth"
                    label="Mobile number*"
                  />
                  {this.state.RequestError.MobileNo !== "" ? (
                    <div className="error mt-2">
                      {this.state.RequestError.MobileNo}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <table className="tblwidth">
                    <tr>
                      <td>
                      <Button
                    label={`Send OTP${
                      this.state.isButtonDisabled
                        ? ` (Retry in ${this.state.timeLeft}s)`
                        : ""
                    }`}
                    icon="pi pi-send"
                    className="p-button-text otpSend"
                    onClick={() => this.SendOtp(this.state.MobileNo)}
                    type="button"
                    disabled={this.state.isButtonDisabled}
                  />
                      </td>
                      <td>
                      <div className="col-md-12 pt-2 NewOtp">
                      <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="OTP"
                    value={this.state.OTP}
                    className="fullwidth"
                    label="OTP *"
                  />
                  {this.state.RequestError.OTP !== "" ? (
                    <div className="error">{this.state.RequestError.OTP}</div>
                  ) : (
                    ""
                  )}
                </div>
                      </td>
                    </tr>
                  </table>
              
                  

                 
                </div>
                <div className="col-md-6 pt-2">
                  <Select
                    value={this.state.selectedState}
                    onChange={this.handleChange}
                    variant="outlined"
                    name="selectedState"
                    displayEmpty
                    className="fullwidth"
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    <MenuItem value="West Bengal">West Bengal</MenuItem>
                    <MenuItem value="Odisha">Odisha</MenuItem>
                  </Select>
                  {this.state.RequestError.selectedState !== "" ? (
                    <div className="error">
                      {this.state.RequestError.selectedState}
                    </div>
                  ) : (
                    ""
                  )}

                  <span className="m-1">
                    Note:
                    <i style={{ color: "red" }}>
                      {" "}
                      This service request is applicable for two states.{" "}
                    </i>
                  </span>
                </div>

                <div className=" col-md-6 pt-2 PinCode">

                <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Pincode"
                    value={this.state.Pincode}
                    className="fullwidth"
                    label="Pincode *"
                  />
                  {this.state.RequestError.Pincode !== "" ? (
                    <div className="error">{this.state.RequestError.Pincode}</div>
                  ) : (
                    ""
                  )}
                 
                </div>

                <div className="col-md-12 pt-1">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="UserFeedback"
                    value={this.state.UserFeedback}
                    className="fullwidth"
                    label="Comment*"
                  />

                  {this.state.RequestError.UserFeedback !== "" ? (
                    <div className="error mt-2">
                      {this.state.RequestError.UserFeedback}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 pt-2">
                  <label htmlFor="productImage">Product Image*</label>
                  <FileUpload
                    name="productImage"
                    mode="basic"
                    url={
                      process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                    }
                    auto={true}
                    onUpload={this.setImagePath}
                    onError={this.onErrorImage}
                    accept="image/*"
                  />

                  {this.state.productImage ? (
                    <div
                      className="p-fileupload-content mt-3 p-1"
                      style={{ display: "table", border: "1px solid #ccc" }}
                    >
                      <div>
                        <img
                          alt="pro.jfif"
                          role="presentation"
                          src={this.state.productImage}
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.RequestError.productImage !== "" ? (
                    <div className="error">
                      {this.state.RequestError.productImage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 pt-2">
                  <label htmlFor="warrantyimage">Warranty Card Image*</label>
                  <FileUpload
                    name="warrantyimage"
                    mode="basic"
                    url={
                      process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                    }
                    auto={true}
                    onUpload={this.setImagePath3}
                    onError={this.onErrorImage}
                    accept="image/*"
                  />

                  {this.state.warrantyimage ? (
                    <div
                      className="p-fileupload-content mt-3 p-1"
                      style={{ display: "table", border: "1px solid #ccc" }}
                    >
                      <div>
                        <img
                          alt="pro.jfif"
                          role="presentation"
                          src={this.state.warrantyimage}
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.RequestError.warrantyimage !== "" ? (
                    <div className="error">
                      {this.state.RequestError.warrantyimage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 pt-2">
                  <label htmlFor="Invoice">Invoice Image*</label>
                  <FileUpload
                    name="Invoice"
                    mode="basic"
                    url={
                      process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"
                    }
                    auto={true}
                    onUpload={this.setImagePath2}
                    onError={this.onErrorImage}
                    accept="image/*"
                  />

                  {this.state.Invoice ? (
                    <div
                      className="p-fileupload-content mt-3 p-1"
                      style={{ display: "table", border: "1px solid #ccc" }}
                    >
                      <div>
                        <img
                          alt="pro.jfif"
                          role="presentation"
                          src={this.state.Invoice}
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.RequestError.Invoice !== "" ? (
                    <div className="error">
                      {this.state.RequestError.Invoice}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <span className="m-4">
                  Note:
                  <i style={{ color: "red" }}>
                    {" "}
                    Pls Upload the Image Upto 6mb{" "}
                  </i>
                </span>
                <div className="col-md-12 mt-3 text-center">
                  <Button label="Submit" icon="pi pi-check" />
                </div>
              </div>
            </form>
          </Dialog>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Rating: state.productAuthentication.rating,
    ProductAuthenticationData:
      (state.productAuthentication.items &&
        state.productAuthentication.items.length) > 0
        ? state.productAuthentication.items[0]
        : null,
    ShowDialogFeedback: state.productAuthentication.showDialogFeedback,
    WarrantyForm: state.productAuthentication.WarrantyForm,
    //showVideo: (state.productAuthentication.items != null && state.productAuthentication.items[0].ResponseCode == "200") ? (state.productAuthentication.items[0].VideoFile != null ? true : false) : false,
    ProductData:
      (typeof state.productAuthentication.prdctddl !== "undefined" &&
        state.productAuthentication.prdctddl !== null) === true
        ? state.productAuthentication?.prdctddl
        : null,
    SelectedProduct: state.productAuthentication.productID,
    isWarrantyRegistered:
      state.productAuthentication.warranty &&
      state.productAuthentication.warranty.length > 0
        ? false
        : true,
    Warranty: state.productAuthentication.warranty,
    VerificationId: state.productAuthentication.VerificationId,
    ShowDialogService: state.productAuthentication.ShowDialogService,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.verifyProduct(productAuth)),
    CreateWarrantyForm: (warranty) =>
      dispatch(productAuthenticationActions.CreateWarrantyForm(warranty)),
    UpdateFeedback: (feedback) =>
      dispatch(productAuthenticationActions.UpdateFeedback(feedback)),
    UpdateRequest: (request) =>
      dispatch(productAuthenticationActions.UpdateRequest(request)),
    updateRating: (ratingData) =>
      dispatch(productAuthenticationActions.updateRating(ratingData)),
    onHideShow1: (isbool) =>
      dispatch(productAuthenticationActions.onHideShow1(isbool)),
    onHideShow: (isbool) =>
      dispatch(productAuthenticationActions.onHideShow(isbool)),
    //onWarrantyHideShow: isbool => dispatch(productAuthenticationActions.onWarrantyHideShow(isbool)),
    fetchProductApi: (obj) => {
      dispatch(productMasterActions.getProductName(obj));
    },
    getPrdctDDL: () => dispatch(productAuthenticationActions.getPrdctDDL()),
    GetProduct: (productId) =>
      dispatch(productAuthenticationActions.GetProductID(productId)),
    GetWarranty: (data) =>
      dispatch(productAuthenticationActions.GetWarranty(data)),
    insertVerification: (productAuth) =>
      dispatch(productAuthenticationActions.insertVerification(productAuth)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAuthentication);
