import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import TextField from '@material-ui/core/TextField';
import { FileUpload } from 'primereact/fileupload';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import { roleMasterActions, userMasterActions } from '../../actions';
import tableRequest from '../../models/tableRequest';
// import { userTypeMasterActions } from '../../actions';
import Switch from '@material-ui/core/Switch';

class userMaster extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.CreatedDateTemplate = this.CreatedDateTemplate.bind(this);
        this.UpdatedDateTemplate = this.UpdatedDateTemplate.bind(this)
        this.resetForm = this.resetForm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.getRoleDDL = this.getRoleDDL.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        const UserTypeData = [
            { value: 1, label: 'Web' },
            { value: 2, label: 'Mobile'}
        ]
        this.state = {
            IsAdd: true,
            IsEdit: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            sortOrder: '',
            buttonLabel: 'Submit',
            switchAction: false,
            formHeading: 'Add User Master',
            UserId: 0,
            RoleId: 0,
            UserTypeId: '',
            UserTypeData : [
                { value: 1, label: 'Web' },
                { value: 2, label: 'Mobile'}
            ],
            UserName: '',
            Email: '',
            FirstName:'',
            LastName:'',
            MobileNo: '',
            Password: '',
            ConfirmPassword: '',
            IsActive: true,
            CreatedDate: null,
            UpdatedDate: null,
            formError: {
                UserName: '',
                FirstName:'',
                LastName:'',
                UserTypeId: '',
                Email: '',
                MobileNo: '',
                Password: '',
                ConfirmPassword: '',
                RoleId: ''
            }
        };
    }
    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser')
        if (userPages) {
            const assignedPermissions = JSON.parse(userPages);
            const Curenturl = window.location.pathname;
            const nNumber = Curenturl.lastIndexOf('/');
            const pageName = Curenturl.substring(nNumber + 1);

            const obj = assignedPermissions.assigned.filter((x) => {
                return x.ActionName === pageName
            });

            if (obj !== null && obj.length > 0) {
                if (!obj[0].IsEdit) {
                    this.setState({
                        IsEdit: false
                    });
                }
                if (!obj[0].IsAdd) {
                    this.setState({
                        IsAdd: false
                    });
                }
            }
        }

        this.props.fetchApi(this.tblResponse);

        const userId = JSON.parse(sessionStorage.getItem("UserData"));
        this.props.getRoleDDL(userId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.UserData) {
            if (prevProps.UserData !== this.props.UserData) {
                // this.props.myProp has a different value
                if (this.props.UserData) {
                    var e = this.props.UserData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }
    }

    switchToggleConfirm = (e) => {
        this.props.inActiveRow(this.state.UserId, this.state.switchAction);
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'IsActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }

    UpdatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDate}</Moment>
            )
        }
        else {

        }
    }

    CreatedDateTemplate(rowData) {
        if (rowData.UpdatedDate !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDate}</Moment>
            )
        }
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'UserName':
                if (value.length < 1) {
                    IsValid = false;
                    errors.UserName = "Please enter user name.";
                } else errors.UserName = ''
                break;
            case 'UserTypeId':
                if (!value) {
                    IsValid = false;
                    errors.UserTypeId = "Please select user type.";
                } else errors.UserTypeId = ''
                break;
            case 'RoleId':
                if (!value) {
                    IsValid = false;
                    errors.RoleId = "Please select user role.";
                } else errors.RoleId = ''
                break;
            case 'FirstName':
                 if (value.length < 1) {
                     IsValid = false;
                     errors.FirstName = "Please enter your first name.";
                 }
                 else if (value.length >= 1) {
                     if (!value.match(/^[A-Za-z]+$/)) {
                         IsValid = false;
                         errors.FirstName = "FirstName should be alpha only."
                     } else errors.FirstName = ''
                 }
                 else errors.FirstName = ''
                 break;
            // case 'LastName':
            //     if (value.length < 1) {
            //         IsValid = false;
            //         errors.LastName = "Please enter your last name.";
            //     }
            //     else if (value.length >= 1) {
            //         if (!value.match(/^[A-Za-z]+$/)) {
            //             IsValid = false;
            //             errors.LastName = "LastName should be alpha only."
            //         } else errors.LastName = ''
            //     }
            //     else errors.LastName = ''
            //     break;
            case 'Email':
                if (value.length < 1) {
                    IsValid = false;
                    errors.Email = "Please enter email-id.";
                }
                else if (value.length >= 1) {
                    /* eslint-disable */
                    if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                        IsValid = false;
                        errors.Email = "Please enter valid email-id.";
                    } else errors.Email = ''
                    /* eslint-enable */
                } else errors.Email = ''
                break;
            case 'MobileNo':
                if (value.length < 1) {
                    IsValid = false;
                    errors.MobileNo = "Please enter mobile no.";
                }
                else if (value.length >= 1) {
                    if (!value.match(/^[6-9]\d{9}$/)) {
                        IsValid = true;
                        errors.MobileNo = "Please enter valid mobile no.";
                    } else errors.MobileNo = ''
                } else errors.MobileNo = ''
                break;
            case 'Password':
                if (!value && value.length < 1) {
                    IsValid = false;
                    errors.Password = "Please enter password.";
                }
                else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/)) {
                    IsValid = false;
                    errors.Password = "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
                }
                else if (value && value.length >= 1 && this.state.ConfirmPassword.length >= 1) {
                    errors.Password = '';
                    errors.ConfirmPassword = '';
                    if (!(value === this.state.ConfirmPassword)) {
                        IsValid = false;
                        errors.Password = "password does not match";
                    } else errors.Password = ''
                } else errors.Password = ''
                break;

            case 'ConfirmPassword':
                if (!value && value.length < 1) {
                    IsValid = false;
                    errors.ConfirmPassword = "Please enter confirm password.";
                }
                else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{4,20}$/)) {
                    IsValid = false;
                    errors.ConfirmPassword = "Password should be minimum 4 characters long, 1 uppercase & 1 lowercase character with 1 number.";
                }
                else if (value && value.length >= 1 && this.state.Password.length >= 1) {
                    errors.Password = '';
                    errors.ConfirmPassword = '';
                    if (!(value === this.state.Password)) {
                        IsValid = false;
                        errors.ConfirmPassword = "Confirm password does not match";
                    } else errors.ConfirmPassword = ''
                } else errors.ConfirmPassword = ''
                break;
            default:
                break;
        }

        return IsValid;
    }

    getDropValueOfRole() {
        let roleName = '';
        this.props.UserRoleData.filter(x => {
            if (x.value === this.state.RoleId.toString()) {
                roleName = x.label;
            }
        });

        return roleName;
    }

    getDropValueOfUserType() {
        let usertype = '';
        this.state.UserTypeData.filter(x => {
            if (x.value === this.state.UserTypeId) {
                usertype = x.label;
            }
        });

        return usertype;
    }

    submitForm(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });

        if (isfrmvalid) {
            const ddlValuRole = this.getDropValueOfRole();
            const ddlValuUserType = this.getDropValueOfUserType();
            const formData = {
                UserId: this.state.UserId,
                RoleId: this.state.RoleId,
                UserName: this.state.UserName,
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                UserType: this.state.UserTypeId,
                Email: this.state.Email,
                MobileNo: this.state.MobileNo,
                Password: this.state.Password,
                IsActive: this.state.IsActive,
                CreatedDate: this.state.UserId > 0 ? this.state.CreatedDate : this.getCurrentDateMMDDYYY(),
                UpdatedDate: this.state.UserId > 0 ? this.getCurrentDateMMDDYYY() : null,
                RoleName: ddlValuRole != null ? ddlValuRole : "",
                TotalRows: this.state.totalRecords
            }
            if (formData.UserId > 0) {
                this.props.updateUser(formData);
            }
            else {
                this.props.createUser(formData);
            }

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }

    getRoleDDL(event) {
        this.props.getRoleDDL();
    }
    resetForm() {
        this.setState({
            UserId: '',
            UserTypeId: '',
            UserName: '',
            FirstName: '',
            Email: '',
            MobileNo: '',
            Password: '',
            ConfirmPassword: '',
            IsActive: true,
            formError: {
                UserName: '',
                UserTypeId: '',
                Email: '',
                FirstName: '',
                MobileNo: '',
                Password: '',
                ConfirmPassword: '',
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add User' })
    }
    statusBodyTemplate(rowData) {

        if (rowData) {
            return (
                <Checkbox
                    disabled
                    checked={rowData.IsActive}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            )
        }
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    actionBodyTemplate(rowData) {
        const editMode = (e) => {
            this.props.onHideShow(true);
            this.setState({
                buttonLabel: 'Update',
                formHeading: 'Update User Master',
                UserId: rowData.UserId == null ? 0 : rowData.UserId,
                UserName: rowData.UserName,
                FirstName: rowData.FirstName,
                LastName : rowData.LastName,
                UserTypeId: rowData.UserType,
                Email: rowData.Email,
                MobileNo: rowData.MobileNo,
                RoleId: rowData.RoleId,
                IsActive: rowData.IsActive,
                Password: rowData.Password,
                ConfirmPassword: rowData.Password,
                CreatedDate: rowData.CreatedDate
            });
        }
        const switchHandleChange = (e) => {
            this.setState({
                switchAction: !rowData.IsActive,
                UserId: rowData.UserId
            });
            this.props.onHideShowIsActive(true);
        }

        if (rowData) {
            return (
                <>
                    {this.state.IsEdit === true ?
                        <div>
                            <Switch checked={rowData.IsActive} color="primary" name={rowData.UserId}
                                onChange={switchHandleChange} inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Button icon="pi pi-pencil" onClick={editMode} />
                        </div>
                        : ""}
                </>
            )
        }
    }

    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.props.onHideShowIsActive(false)} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>User Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>
                    {this.state.IsAdd === true ?
                        <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} />
                        : ""}
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">
                    <DataTable value={this.props.UserData}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.totalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>
                        <Column field="FirstName" header="Name" sortable></Column>
                        <Column field="RoleName" header="Role Name" sortable></Column>
                        <Column field="Email" header="Email" sortable></Column>
                        <Column field="MobileNo" header="Mobile Number" sortable></Column>
                        {this.state.IsEdit === true ?
                            <Column field="Action" header="Action" style={{ width: '120px' }} body={this.actionBodyTemplate}></Column>
                            : <Column field="Action" header="Action" style={{ width: '120px' }} style={{ display: 'none' }}></Column>}
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={true} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                    <form onSubmit={this.submitForm} autocomplete="off">
                        <div className="row mt-3">
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} name="UserName" autoComplete="false" value={this.state.UserName} size="small" variant="outlined" className="fullwidth" label="User Name*" />
                                {this.state.formError.UserName !== '' ?
                                    <div className='error'>{this.state.formError.UserName}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select User Role*</InputLabel>
                                    <Select onChange={this.handleChange} name="RoleId" value={this.state.RoleId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select User Role">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.UserRoleData == (undefined || null) ? null : this.props.UserRoleData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>
                                        )
                                        )}
                                    </Select>
                                </FormControl>
                                {this.state.formError.RoleId !== '' ?
                                    <div className='error'>{this.state.formError.RoleId}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select User Type*</InputLabel>
                                    <Select onChange={this.handleChange} name="UserTypeId" value={this.state.UserTypeId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select User Type">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>
                                        {this.state.UserTypeData.map(key => (
                                            <MenuItem value={key.value}>{key.label}</MenuItem>
                                        ))}
                                        
                                    </Select>
                                </FormControl>
                                {this.state.formError.UserTypeId !== '' ?
                                    <div className='error'>{this.state.formError.UserTypeId}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} name="Email" value={this.state.Email} size="small" variant="outlined" className="fullwidth" label="Email*" />
                                {this.state.formError.Email !== '' ?
                                    <div className='error'>{this.state.formError.Email}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} name="FirstName" autoComplete="false" value={this.state.FirstName} size="small" variant="outlined" className="fullwidth" label="First Name*" />
                                {this.state.formError.FirstName !== '' ?
                                    <div className='error'>{this.state.formError.FirstName}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} name="LastName" autoComplete="false" value={this.state.LastName} size="small" variant="outlined" className="fullwidth" label="Last Name" />
                            </div>
                            {/* <div className="col-md-6 pt-3">
                                <TextField onChange={this.handleChange} name="MobileNo" value={this.state.MobileNo} size="small" variant="outlined" className="fullwidth" label="Mobile Number*" />
                                {this.state.formError.MobileNo !== '' ?
                                    <div className='error'>{this.state.formError.MobileNo}</div> : ''}
                            </div> */}
                            <div className="col-md-6 pt-3">
                                <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                    name="MobileNo" value={this.state.MobileNo} className="fullwidth" label="Mobile Number*" />
                                {this.state.formError.MobileNo !==
                                    '' ?
                                    <div className='error'>{this.state.formError.MobileNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <TextField input type="password" autocomplete="new-password" onChange={this.handleChange} name="Password" value={this.state.Password} size="small" variant="outlined" className="fullwidth" label="Password*" />
                                {this.state.formError.Password !== '' ?
                                    <div className='error'>{this.state.formError.Password}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <TextField input type="password" onChange={this.handleChange} name="ConfirmPassword" value={this.state.ConfirmPassword} size="small" variant="outlined" className="fullwidth" label="Confirm Password*" />
                                {this.state.formError.ConfirmPassword !== '' ?
                                    <div className='error'>{this.state.formError.ConfirmPassword}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-3">
                                <Checkbox
                                    name="IsActive"
                                    checked={this.state.IsActive}
                                    onClick={this.handleChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                /> <label>Is Active</label>
                            </div>

                            <div className="col-md-12 mt-3 mb-3 text-center">
                                {this.state.IsEdit === true ?
                                    <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    : ""}
                            </div>
                            <div class="col-md-12">
                                Note: Fields marked as (*) are required
                            </div>
                        </div>
                    </form>
                </Dialog>
                <Dialog header="Confirmation" visible={this.props.showIsActiveDialog} modal style={{ width: '350px' }}
                    footer={renderFooter('displayConfirmation')} onHide={() => this.props.onHideShowIsActive(false)}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to {this.state.switchAction == true ? "Active" : "InActive"}?</span>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        UserData: state.user.items,
        UserRoleData: state.role.rollddl,
        showDialog: state.role.showDialog,
        loading: state.company.loding,
        showIsActiveDialog: state.user.showIsActiveDialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(userMasterActions.getAll(obj))
        },
        createUser: user => dispatch(userMasterActions.createUser(user)),
        updateUser: user => dispatch(userMasterActions.updateUser(user)),
        inActiveRow: (user, isActive) => dispatch(userMasterActions.inActiveRow(user, isActive)),
        onHideShow: isbool => dispatch(userMasterActions.onHideShow(isbool)),
        onHideShowIsActive: isbool => dispatch(userMasterActions.onHideShowIsActive(isbool)),
        getRoleDDL: (userId) => dispatch(roleMasterActions.getRoleDDL(userId)),
        loadingData: (isLoding) => dispatch(userMasterActions.loadingData(isLoding))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(userMaster)