export function authHeader() {
    // return authorization header with jwt token

    let user = JSON.parse(sessionStorage.getItem('user'));
    const Curenturl = window.location.pathname;
    const nNumber = Curenturl.lastIndexOf('/');
    const pageName = Curenturl.substring(nNumber + 1);

    if (user && user.Token) {
        return {
            'Authorization': 'Bearer ' + user.Token,
            'page': pageName,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            //'Ocp-Apim-Subscription-Key': ` ${this.appContextService.subscriptionKey}`,
            'X-Frame-Options': 'Deny',
            'X-XSS-Protection': '1',
            'X-Content-Type-Options': 'nosniff',
            'cache-control': 'max-age=3153600'
        };
    } else {
        sessionStorage.clear();
        //window.location = '/login'
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
  
        const originalReq = response.config;
        const curl = window.location.pathname;
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response !== undefined && response.status === 418 && !curl.includes("/login") && !curl.includes(('/pa'))) {
                const obj = {
                    ResponseMessage: "User unauthorized, please contact admin.",
                    ResponseCode: response.status
                }
               
                return obj;
            }
            else if (response.status === 401) {
                // auto logout if 401 response returned from api
                sessionStorage.clear();
                //window.location = '/login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function logout() {
    sessionStorage.clear();
}