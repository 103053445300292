// request for lazy loading for all pages
export default class tableRequest {
    IsActive = true;
    PageNo = 1;
    First = 1;
    SearchValue = "";
    PageSize = 5;
    SortColumn = "VerifiedDate";
    SortOrder = "DESC";
    FromDate = "";
    ToDate = "";
    UserId=JSON.parse(sessionStorage.getItem("UserData"));
}