import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { issueNoActions, demappingActions } from '../../actions';
import tableRequest from '../../models/tableRequest.js';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'; 

class IssueNumber extends React.Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        // this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        // this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.onHide = this.onHide.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.state = {
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            sortOrder: '',
            confirm: false,
            buttonLabel: 'Submit',
            formHeading: 'Add Issue No',
            displayModal: false,
            switchAction: false,
            showSnackbar: false,
            displayConfirmation: false,
            ProductName: '',
            SalesOrderNo: '',
            updateLoader: false,
            IssueId: 0,
            CreatedDt: null,
            UpdatedDt: null,
            ProductId: 0,
            ProductName: '',
            FromNo: '',
            ToNo: '',
            IssueQty: '',
            IsActive: true,
            formError: {
                ProductId: '',
                FromNo: '',
                ToNo: '',
                IssueQty: '',
                SalesOrderNo: '',
                CreatedDt: null,
                UpdatedDt: null,

            }
        };
    }
    //to trigger api on page call
    componentDidMount() {
        this.props.fetchApi(this.tblResponse);
        this.props.fetchFromNumberApi(this.tblResponse);
        this.props.genericProducts();
    }

    componentDidUpdate(prevProps) {
        if (this.props.IssueData) {
            if (prevProps.IssueData !== this.props.IssueData) {
                // this.props.myProp has a different value
                if (this.props.IssueData) {
                    var e = this.props.IssueData;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }
        if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
            this.setState({
                FromNo: this.props.MaxFromNo
            });
        }
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    getDropValue() {
        let productId = '';
        this.props.ProductData.filter(x => {
            if (x.ProductId === this.state.ProductId) {
                productId = x.ProductId;
            }
        });

        return productId;
    }
    //to manage switch toggle confirm box
    switchToggleConfirm = (e) => {
        this.setState({ confirm: true, displayConfirmation: false })
        const formData = {
            MachineId: this.state.MachineId
        }
        this.props.inActiveRow(this.state.MachineId);
    }

    //to handle form
    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        // switch (name) {
        //     case 'IsActive':
        //         value = event.target.checked;
        //         break;
        //     default:
        //         break;
        // }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    //to validate form data
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;

        switch (name) {
             case 'FrmNo':
                 if (value.length < 1) {
                     IsValid = false;
                     errors.FrmNo = "Please enter FrmNo.";
                 } else errors.FrmNo = ''
                 break;

            // case 'ToNo':
            // if (value.length < 1) {
            //     IsValid = false;
            //     errors.ToNo = "Please enter ToNo.";
            // } else errors.ToNo = ''
            // break;
            case 'IssueQty':
                if (value.length < 1) {
                    IsValid = false;
                    errors.IssueQty = "Please enter your quantity.";
                }
                else {
                    if (!value.match(/^[0-9]+$/)) {
                        IsValid = false;
                        errors.IssueQty = "Please enter number greater than zero.";
                        this.setState({ IssueQty: 0 })
                    } else {

                        errors.ToNo = null;
                        errors.IssueQty = null;
                        errors.FromNo = null;
                        const qty = value.trim() === '' ? 0 : parseInt(value.trim());
                        const fromNumber = this.state.FromNo.toString().trim() === '' ? 0 : parseInt(this.state.FromNo.toString().trim());
                        if (fromNumber >= 1 && qty >= 1) {
                            const toNumber = (qty + fromNumber) - 1;
                            if (toNumber >= 0) {
                                this.setState({ ToNo: toNumber })
                            } else {
                                IsValid = false;
                                errors.IssueQty = "To number should be greater than or equal to from number.";
                                this.setState({ ToNo: 0 })
                            }

                        } else {
                            // IsValid = false;
                            // errors.ToNo = "To number should be greater than from number.";
                            this.setState({ ToNo: 0 })
                        }
                    }
                }
                break;
            case 'ProductId':
                if (value === 0) {
                    IsValid = false;
                    errors.ProductId = "Please select Product";
                } else errors.ProductId = null
                break;
            case 'SalesOrderNo':
                if (value.length < 1) {
                    IsValid = false;
                    errors.SalesOrderNo = "Please enter sales order no.";
                } else errors.SalesOrderNo = ''
                break;


            default:
                break;
        }

        return IsValid;
    }
    //to get current date
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }
    //to submit form data
    submitForm(event) {
        event.preventDefault();
        let isfromvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfromvalid });
                isfromvalid = false;
            }
            return isfromvalid;
        });

        if (isfromvalid) {
            const ddlValu = this.getDropValue();

            const formData = {
                IssueId: this.state.IssueId,
                ProductId: this.state.ProductId,
                ProductName : ddlValu != null ? ddlValu : "",
                FromNo: this.state.FromNo,
                ToNo: this.state.ToNo,
                IssueQty: this.state.IssueQty,
                SalesOrderNo: this.state.SalesOrderNo,
                CreatedDt: this.state.IssueId > 0 ? this.state.CreatedDt : this.getCurrentDateMMDDYYY(),
                UpdatedDt: this.state.IssueId > 0 ? this.getCurrentDateMMDDYYY() : null,
                TotalRows:this.state.totalRecords
            }

            if (this.state.IssueId == 0) {
                this.props.createIssueNo(formData);
            }
        }
    }

    //to reset form
    resetForm() {
        this.setState({
            IssueQty: '',
            IssueId: 0,
            FromNo: '',
            ToNo: '',
            IssueQty: '',
            ProductId:0,
            ProductName:'',
            SalesOrderNo: '',
            formError: {
                FromNo: '',
                ToNo: '',
                ProductId:'',
                IssueQty: '',
                SalesOrderNo: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }
    //to hide function
    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Submit', formHeading: 'Add Issue No' })

        this.props.fetchFromNumberApi(this.tblResponse);
    }
    getDropValue() {
        let productId = '';
        this.props.genericProductsDDL.filter(x => {
            if (x.productId === parseInt(this.state.ProductId)) {
                productId = x.label;
            }
        });
        return productId;
    }
    //to get format of date
    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>
            )
        }
    }
    //to get format of date
    CreatedDtTemplate(rowData) {
        if (rowData.CreatedDt !== null) {

            return (
                <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>
            )
        }
    }

    //to disable checkbox
    // statusBodyTemplate(rowData) {
    //     return (
    //         <Checkbox
    //             defaultChecked
    //             disabled
    //             checked={rowData.IsActive}
    //             color="primary"
    //             inputProps={{ 'aria-label': 'secondary checkbox' }}
    //         />
    //     )
    // }    
    //to edit rowdata

    //to export data
    exportCSV() {
        this.dt.exportCSV();
    }

    //to render html
    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    {/* <Button label="No" icon="pi pi-times" onClick={() => this.setState({ displayConfirmation: false })} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus /> */}
                </div>
            );
        }

        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4>Issue Number</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>
                    <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={(e) => this.openDialog()} />
                    {/* <Button type="button" icon="pi pi-external-link" label="Export" onClick={this.exportCSV}></Button> */}
                </div>
            </div>
        );

        return (

            <div className="datatable-filter-demo">
                <ToastContainer />
                <div className="card">
                    <DataTable value={this.props.IssueData}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={this.state.totalRecords}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>
                        {/* <Column field="IssueId" header="Issue Id" sortable></Column> */}
                        <Column field="ProductName" header="Product Type" sortable></Column>
                        <Column field="SalesOrderNo" header="Sales Order No" sortable></Column>
                        <Column field="FromNo" header="From No" sortable></Column>
                        <Column field="ToNo" header="To No" sortable></Column>
                        <Column field="IssueQty" header="Quantity" sortable></Column>


                        {/* <Column field="CreatedDt" body={this.CreatedDtTemplate} header="Created Date" sortable></Column>
                        <Column field="UpdatedDt" body={this.UpdatedDtTemplate} header="Updated Date" sortable></Column> */}
                        {/* <Column field="Status" style={{ width: '70px' }} header="Status" body={this.statusBodyTemplate}></Column> */}
                        {/* <Column field="Action" header="Action" style={{ width: '120px' }} body={this.actionBodyTemplate}></Column> */}
                    </DataTable>
                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} style={{ width: '50vw' }} onHide={this.onHide}>
                    <form onSubmit={this.submitForm}>
                        <div className="row mt-3">
                            {/* <div className="col-md-6 pt-2">
                                <FormControl variant="outlined" size="small" className="formControl col-md-12">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Product</InputLabel>
                                    <Select onChange={this.handleChange} name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product">
                                        <MenuItem>
                                            <em>None</em>
                                        </MenuItem>

                                        {this.props.ProductData == (undefined || null) ? "" : this.props.ProductData.map(key => (
                                            <MenuItem value={key.ProductId}>{key.ProductName}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                                {this.state.formError.ProductId !== '' ?
                                    <div className='error'>{this.state.formError.ProductId}</div> : ''}
                            </div> */}
                            <div className="col-md-6 pt-2">
                            <FormControl variant="outlined" size="small" className="formControl col-md-12">
                            <InputLabel id="demo-simple-select-outlined-label">Select Product Type*</InputLabel>
                                <Select onChange={this.handleChange} name="ProductId" value={this.state.ProductId} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Product Type">
                                    <MenuItem>
                                        <em>None</em>
                                    </MenuItem>
                                        {
                                            (typeof (this.props.genericProductsDDL) == "undefined" || this.props.genericProductsDDL == null) === true ? null : this.props.genericProductsDDL?.map(key => (
                                                <MenuItem value={key.value}>{key.label}</MenuItem>
                                            ))
                                        }
                                </Select>
                            </FormControl>
                            {this.state.formError.ProductId !== '' ?
                                            <div className='error'>{this.state.formError.ProductId}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.SalesOrderNo} name="SalesOrderNo" size="small" variant="outlined" className="fullwidth" label="Sales Order No*" />
                                {this.state.formError.SalesOrderNo !== '' ?
                                    <div className='error'>{this.state.formError.SalesOrderNo}</div> : ''}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.FromNo} name="FromNo" size="small" variant="outlined" className="fullwidth" label="From No*" />
                                 {this.state.formError.FrmNo !== '' ?
                                    <div className='error'>{this.state.formError.FrmNo}</div> : ''} 
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} disabled value={this.state.ToNo} name="ToNo" size="small" variant="outlined" className="fullwidth" label="To No*" />
                                {/* {this.state.formError.ToNo !== '' ?
                                    <div className='error'>{this.state.formError.ToNo}</div> : ''} */}
                            </div>
                            <div className="col-md-6 pt-2">
                                <TextField onChange={this.handleChange} value={this.state.IssueQty} name="IssueQty" size="small" variant="outlined" className="fullwidth" label="IssueQty*" />
                                {this.state.formError.IssueQty !== '' ?
                                    <div className='error'>{this.state.formError.IssueQty}</div> : ''}
                            </div>
                            <div className="col-md-12 mt-3 mb-3 text-center">
                                <Button label={this.state.buttonLabel} icon="pi pi-check" />
                            </div>

                            <div className="col-md-12">
                                Note: Fields marked as (*) are requiredpp
                            </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={renderFooter('displayConfirmation')} onHide={() => this.setState({ displayConfirmation: false })}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to InActive?</span>
                    </div>
                </Dialog>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        IssueData: state.issueNo.items,
        MaxFromNo: state.demapping?.items?.FromNo,
        showDialog: state.issueNo.showDialog,
        loading: state.issueNo.loding,
        genericProductsDDL: state.issueNo.genericProductsDDL
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (obj) => {
            dispatch(issueNoActions.getAll(obj))
        },
        fetchFromNumberApi: (obj) => {
            dispatch(demappingActions.getFromNumber(obj))
        },
        onHideShow: isbool => dispatch(issueNoActions.onHideShow(isbool)),
        createIssueNo: issueData => dispatch(issueNoActions.createIssueNo(issueData)),
        loadingData: (isLoding) => dispatch(issueNoActions.loadingData(isLoding)),
        genericProducts: () => dispatch(issueNoActions.genericProducts())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IssueNumber)