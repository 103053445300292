import { productAuthenticationService } from '../services';
import { productAuthenticationConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const productAuthenticationActions = {
    verifyProduct,
    UpdateFeedback,
    UpdateRequest,
    updateRating,
    onHideShow,
    onHideShow1,
    getPrdctDDL,
    GetProductID,
    GetWarranty,
    onProductHideShow,
    CreateWarrantyForm,
    onWarrantyHideShow,
    insertVerification
};

function verifyProduct(data) {
    return dispatch => {
        dispatch(request(data));
        productAuthenticationService.verifyProduct(data).then(
            response => {
                dispatch(success(response.ResponseData));
                //history.push('/');
                if (response && response.ResponseCode) {
                    // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                    //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                    //         : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.CREATE_FAILURE, error } }
};

function UpdateFeedback(data) {
    return dispatch => {
        productAuthenticationService.UpdateFeedback(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        //dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_FAILURE, error } }
};
function UpdateRequest(data) {
    return dispatch => {
        productAuthenticationService.UpdateRequest(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        //dispatch(success(response.ResponseData));
                        dispatch(onHideShow1(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_FEEDBACK_FAILURE, error } }
};


function updateRating(data) {
    return dispatch => {
        productAuthenticationService.updateRating(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                failure(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.UPDATE_RATING_REQUEST, data } }
    function success(data) { return { type: productAuthenticationConstants.UPDATE_RATING_SUCCESS, data } }
    function failure(error) { return { type: productAuthenticationConstants.UPDATE_RATING_FAILURE, error } }
};

function onHideShow(isbool) {
    
    return {
        type: productAuthenticationConstants.FEEDBACK_DIALOG_REQUEST, isbool
    }
}

function onHideShow1(isbool) {
    
    return {
        type: productAuthenticationConstants.SERVICE_DIALOG_REQUEST, isbool
    }
}

function onProductHideShow(isbool) {
    
    return {
        type: productAuthenticationConstants.PRODUCT_DIALOG_REQUEST, isbool
    }
}

function onWarrantyHideShow(isbool) {
    
    return {
        type: productAuthenticationConstants.WARRANTY_DIALOG_REQUEST, isbool
    }
}

function getPrdctDDL() {
    return dispatch => {
        dispatch(request());

        productAuthenticationService.fetchProductDLL().then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: productAuthenticationConstants.GETPRDCTDDL_REQUEST } }
    function success(datas) { return { type: productAuthenticationConstants.GETPRDCTDDL_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.GETPRDCTDDL_FAILURE, error } }
};

function GetProductID(data) {
    
    return dispatch => {
        dispatch(request());

        productAuthenticationService.fetchProductID(data).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: productAuthenticationConstants.GETPRDCTID_REQUEST } }
    function success(datas) { return { type: productAuthenticationConstants.GETPRDCTID_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.GETPRDCTID_FAILURE, error } }
};

function GetWarranty(data) {
    
    return dispatch => {
        dispatch(request());

        productAuthenticationService.fetchWarranty(data).then(response => {
            
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: productAuthenticationConstants.GETWARRANTY_REQUEST } }
    function success(datas) { return { type: productAuthenticationConstants.GETWARRANTY_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.GETWARRANTY_FAILURE, error } }
};

function CreateWarrantyForm(data) {
    return dispatch => {
        dispatch(request());
        productAuthenticationService.CreateWarrantyForm(data).then(
            response => {
                
                if (response && response.ResponseCode) {

                    if (response.ResponseCode === "200") {

                        dispatch(success(response));

                        dispatch(alertActions.success(response.ResponseMessage));

                    } else if (response.ResponseCode === "400") {

                        dispatch(alertActions.warning(response.ResponseMessage))

                    } else {

                        dispatch(alertActions.error(response.ResponseMessage))

                    }

                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: productAuthenticationConstants.CREATE_WARRANTY_REQUEST } }
    function success(datas) { return { type: productAuthenticationConstants.CREATE_WARRANTY_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.CREATE_WARRANTY_FAILURE, error } }
};

function insertVerification(data) {
    return dispatch => {
        dispatch(request(data));
        productAuthenticationService.insertVerification(data).then(
            response => {
                
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: productAuthenticationConstants.INSERT_VERIFICATION_REQUEST, data } }
    function success(datas) { return { type: productAuthenticationConstants.INSERT_VERIFICATION_SUCCESS, datas } }
    function failure(error) { return { type: productAuthenticationConstants.INSERT_VERIFICATION_FAILURE, error } }
};





