import { verificationLogServices } from '../services';
import { verificationLogConstants } from '../constants';
import { alertActions } from '.';

export const verificationLogActions = {
    getAll,
    loadingData,
    exportData
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());
        verificationLogServices.fetchVerificationData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))

            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows; delete v.ProductId });
            }
            dispatch(successExport(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }

        );
    };
    function request() { return { type: verificationLogConstants.GETALL_REQUEST } }
    function success(datas) { return { type: verificationLogConstants.GETALL_SUCCESS, datas } }
    function successExport(datas) { return { type: verificationLogConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: verificationLogConstants.GETALL_FAILURE, error } }
}

function exportData(obj) {
    return dispatch => {
        dispatch(request());
        verificationLogServices.fetchVerificationData(obj).then(response => {
            dispatch(loadingData(false));
            var data = response.ResponseData;
            if (data != null) {
                data.forEach(function (v) { delete v.TotalRows; delete v.ProductId });
            }
            dispatch(success(data))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };
    function request() { return { type: verificationLogConstants.GETEXPORT_REQUEST } }
    function success(datas) { return { type: verificationLogConstants.GETEXPORT_SUCCESS, datas } }
    function failure(error) { return { type: verificationLogConstants.GETEXPORT_FAILURE, error } }
}

function loadingData(isbool) {
    return {
        type: verificationLogConstants.LOADING_REQUEST, isbool
    }
}
