import React, { Component } from 'react'
import Avtar from './../assets/images/avatar.jpg';
import { Menubar } from 'primereact/menubar';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import logo from './../assets/images/Header_logo.png';
import { connect } from 'react-redux';
import { menuActions } from "../../src/actions";

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nav: false,
            UserName:''
        }

        this.navigation = [
            {
                label: 'Dashboard',
                icon: "pi pi-palette",
                url: '/'
            },
            {
                label: 'Masters',
                icon: 'pi pi-user',
                items: [
                    {
                        label:'Company Master',
                        url:'/masters/company-master',
                        icon:'pi pi-angle-right'
                    },
                    {
                        label: 'User Master',
                        url: '/masters/user-master',
                        icon: 'pi pi-angle-right'
                    },
                    {
                        label: 'Product Master',
                        url: '/masters/product-master',
                        icon: 'pi pi-angle-right'
                    },  
                    {
                        label:'Product Configuration',
                        url:'/masters/product-configuration',
                        icon:'pi pi-angle-right'
                    },                 
                    {
                        label: 'Role Master / Access Right',
                        url: '/masters/role-master',
                        icon: 'pi pi-angle-right'
                    },
                    {
                        label: 'SQL Editor',
                        url: '/masters/sql-editor',
                        icon: 'pi pi-angle-right'
                    }                       
                ]
            },
            {
                label: 'Activation',
                icon: 'pi pi-fw pi-pencil',
                items: [                   
                    {
                        label: 'Issue Number',
                        url: '/activation/issue-number',
                        icon: 'pi pi-angle-right'
                    }
                ]
            },
            {
                label: 'Transactions',
                icon: 'pi pi-fw pi-pencil',
                items: [
                   
                    {
                        label: 'Mapping',
                        url: '/transactions/mapping',
                        icon: 'pi pi-angle-right'
                    },
                    {
                        label: 'De-mapping',
                        url: '/transactions/de-mapping',
                        icon: 'pi pi-angle-right'
                    },
                    {
                        label: 'Damage',
                        url: '/transactions/damage',
                        icon: 'pi pi-angle-right'
                    }
                ]
            },
            {
                label: 'Reports',
                icon: 'pi pi-chart-bar',
                items: [
                    {
                        label: 'Verification Log',
                        url: '/reports/verification-log',
                        icon: 'pi pi-angle-right'
                    },
                    {
                        label: 'Mapping Report',
                        url: '/reports/mapping-report',
                        icon: 'pi pi-angle-right',
                    },
                    {
                        label: 'Feedback Report',
                        url: '/reports/',
                        icon: 'pi pi-angle-right'
                    }
                ]
            },
        ];
    }
    listener = null;

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        let roleId = (sessionStorage.getItem("UserRole"));
        this.props.fetchApi(roleId);
        this.props.getAccessRight(roleId);
        let userNameCollector = (sessionStorage.getItem("UserNameData"));
        let user = JSON.parse(sessionStorage.getItem("user"));
        let username = user.FirstName+ ' ' +user.LastName 
        this.setState({ UserName: username});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', null);
    }

    handleScroll = () => {
        if (window.pageYOffset > 74) {
            if (!this.state.nav) {
                this.setState({ nav: true });
            }
        } else {
            if (this.state.nav) {
                this.setState({ nav: false });
            }
        }
    }

    render() {

        return (
            <div className="row header-bg">
                <div className="col-md-3 logo-margin" style={{ background: '' }}>
                   <a href="/" className="ml-4"><img src={logo} /></a>
                </div>
                <div className="col-md-9" style={{ background: '' }}>
                    <div className="user-area dropdown text-right">
                        <span className="dropdown-toggle active"> Welcome, {this.state.UserName}
                             <img alt="User Avatar" className="user-avatar rounded-circle" src={Avtar} />
                        </span>
                        <div className="user-menu">
                            <div className="nav-link" onClick={() => window.location = '/profile'}><i className="fa fa-key"></i>Change Password</div>
                            <div className="nav-link" onClick={(e) => { sessionStorage.removeItem("LoggedInUser"); window.location = '/login' }}><i className="fa fa-power-off"></i>Logout</div>
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        MenuData: state.menu.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (roleId) => {
            dispatch(menuActions.getMenu(roleId))
        },
        getAccessRight: (roleId) => {
            dispatch(menuActions.getAccessRight(roleId))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
